import api from "./config/axiosConfig";



export const getList = async (controller) => {

    return await api.get('company/groups', { signal: controller.signal })

}


export const deleteGroup = async (id) => {
    return await api.delete('company/groups/' + id)
}

export const create = async (data) => {
    return await api.post('company/groups', data)

}


export const update = async (id, data) => {
    return await api.put('company/groups/'+id, data)

}