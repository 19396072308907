import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../../components/shared/Utils";
import { useNavigate } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import PointsLabel from "./../PointsLabel"
import { useIsAllowed } from "../../../../components/shared/IsAllowed";



const css = { align: 'center', headerAlign: 'center' }
const sortingOrder = { sortingOrder: ['desc', 'asc'] }

export const useColumnsDefinition = () => {

    const { t } = useTranslation('main')
    let navigate = useNavigate()
    const isAllowed = useIsAllowed()

    const handleClick = (params) => {
        
        return navigate('/app/ideas/' + params.idea.id)
    }

    let protectedColumns = []

    if (isAllowed('rolePointsHistoryViewAll')) {
        protectedColumns = [
            {
                field: 'u.lastName',
                headerName: t('fullname'),
                flex: 1,
                ...css,
                ...sortingOrder,
                valueGetter: (params) =>
                    `${params.row.user.lastName } ${params.row.user.firstName    }`,
            },
            {
                field: 'u.symbol',
                headerName: t('employeeSymbol'),
                flex: 1,
                ...css,
                ...sortingOrder,
                valueGetter: (params) =>
                    `${params.row.user.symbol || '-'}`,
            }
        ]
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 70, ...css, ...sortingOrder, },
        {
            field: 'i.ideaNr',
            headerName: t('ideaNr'),
            flex: 1,
            ...css,
            ...sortingOrder,
            renderCell: (params) => {
                let content = '-'
                if(params.row.idea?.title) {
                    content = <Tooltip title={params.row.idea.title}>
                        <Link sx={{ cursor: 'pointer' }} onClick={() => handleClick(params.row)} >{params.row.idea.ideaNr}</Link>
                    </Tooltip>
                } 

                return <div>{content}</div>
            
            }
        },
        ...protectedColumns,
        {
            field: 'created',
            headerName: t('addPointsHistoryDate'),
            width: 180,
            ...css,
            ...sortingOrder,
            valueFormatter: ({ value }) => dateFormat(value),

        },
        {
            field: 'pht.type',
            headerName: t('pointsFor'),
            width: 180,
            ...css,
            ...sortingOrder,
            valueGetter: (params) => `${params.row.type.name || ''}`,

        },
        {
            field: 'points',
            headerName: t('pointsAmount'),
            width: 200,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <PointsLabel points={params.row.points} type={params.row.type.type} />
            )

        },


     
    ];

    return columns;
}