
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { downloadCredentials } from '../../../apis/UsersApi';
import { useResponseHandler } from '../../../hooks/ResponseHandler';
import fileDownload from 'js-file-download';


export default function PrintCredentialsDialog(props) {


    const { t } = useTranslation('main')
    const [handleError] = useResponseHandler()

    const handleYesClick = () => {
      
        downloadCredentials(props.credentials).catch((e) => {
            handleError(e)
        }).then((res) => {
            res && fileDownload(res.data, `Dane_uzytkownika.pdf`)
            props.onFinish()
        })
    }

    


    return (
        <Dialog
            maxWidth="xs"
            open={props.open}
        >

            <DialogContent>
                {t('doYouWantToPrintCredentials')}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onFinish}>{t('no')}</Button>
                <Button onClick={handleYesClick}>{t('yes')}</Button>
            </DialogActions>
        </Dialog>
    );



}