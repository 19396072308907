import { useTheme } from "@mui/material";
import { format } from "date-fns";
import { tokens } from "../../theme";

export const useColors = () => {
    const theme = useTheme()
    return tokens(theme.palette.mode)
}


export const currency = (value) => {
    if (!value)
        value = 0;

    value = parseFloat(value).toFixed(2);
    return value.replace('.', ',') + ' zł';

}


export const dateFormat = (value) => {

    if (!value) {
        return '-';
    }

    const date = new Date(value);

    return format(date, "yyyy-MM-dd");

}

export const datetimeFormat = (value) => {

    if (!value) {
        return '-';
    }

    const date = new Date(value);

    return format(date, "yyyy-MM-dd HH:mm");

}



export const flatObject = (obj, exclude) => {

    

    if (typeof obj !== 'object')
        return obj;

    for (const prop in obj) {

        if(exclude && exclude.includes(prop)) 
            continue;
        

        if ((typeof obj[prop] === 'object') && (obj[prop] !== null) && obj[prop].hasOwnProperty('id'))
            obj[prop] = obj[prop].id

    }

    return obj;

}

export const nullToEmptyString = (obj, nullable) => {

    for (const prop in obj) {

        if (obj[prop] !== null) {
            continue;
        }

        if (Array.isArray(nullable) && nullable.includes(prop))
            continue;

        obj[prop] = ''
    }
    return obj;
}



export const fixDates = (obj) => {

    Object.keys(obj).forEach(key => {

        if (typeof obj[key] === 'object' && obj[key] !== null)


            if (typeof obj[key] === 'object' && obj[key] !== null) {
                if (obj[key] instanceof Date) {
                    let date = obj[key];

                    obj[key] = date.toLocaleString("default", { year: "numeric" }) + "-" +
                        date.toLocaleString("default", { month: "2-digit" }) + "-" +
                        date.toLocaleString("default", { day: "2-digit" });

                } else {
                    obj[key] = fixDates(obj[key])
                }

            }
    })

    return obj;

}


export const generatePassword = () => {

    const length = 8
    const charset = "abcdefghijkmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;

}



