import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormTemplate from "../../../components/shared/form/FormTemplate";
import HorizontalLabel from "../../../components/shared/HorizontalLabel";
import CompanyGroupSelect from "../../../components/shared/input/CompanyGroupSelect";
import { useResponseHandler } from "../../../hooks/ResponseHandler";
import Attachments from "./../components/Attachments";
import EditFields from "./EditFields";




function ManageIdea(props) {



    const { t } = useTranslation('main');
    const [isLoading, setIsLoading] = useState(false)
    const [attachments, setAttachments] = useState(props.defaultValues?.idea?.attachments ?? [])
    const [responseCode, setResponseCode] = useState(0)
    const [handleError, handleSuccess] = useResponseHandler()

    const { register, control, handleSubmit, formState: { errors } } = useForm({ defaultValues: props.defaultValues, resolver: yupResolver(props.schema) });



    const onSubmit = (formData) => {
        setIsLoading(true)


        const save = async (params) => {
            await props.save(params).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response);
                    if (props.onSuccess)
                        return props.onSuccess()

                }

                setIsLoading(false)
            })
        }

        save({ ...formData, attachments: { ...attachments } })

    }

    

    const updateAttachments = (list) => {
        setAttachments(list);
    }

    let headerChildren = null;

    if (props.editMode) {
        headerChildren = (<>
            <Grid container alignItems="flex-end">
                <Grid item xs={12} sm={4} >
                    <HorizontalLabel label={t('ideaNr')} value={props.defaultValues?.idea?.ideaNr} valueVariant="h5" />
                </Grid>
                <Grid item xs={12} sm={4}><HorizontalLabel label={t('employeeSymbol')} value={props.fullData?.user?.symbol} /></Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'left', sm: 'right' } }}><HorizontalLabel label={t('ideaCreated')} value={props.defaultValues?.idea?.created} /></Grid>
            </Grid>
        </>)
    }


    return (
        <FormTemplate
            formTitle={props.formTitle}
            responseCode={responseCode}
            handleSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            headerChildren={headerChildren}>

            <Grid container sx={{ px: 3, pt: 0, pb: 3 }} spacing={5} justifyContent="space-between">
                <Grid item xs={12} md={7} >


                    <Box>
                        <TextField
                            {...register('idea.title')}
                            fullWidth
                            autoComplete="off"
                            size="small"
                            label={t('ideaTitle')}
                            error={!!errors.idea?.title}
                            helperText={errors.idea?.title?.message} />
                    </Box>
                    <Box sx={{ pt: 3 }}>
                        <TextField
                            {...register('idea.problemDescription')}
                            fullWidth
                            label={t('issueDescription')}
                            minRows={3}
                            multiline
                            error={!!errors.idea?.problemDescription}
                            helperText={errors.idea?.problemDescription?.message}
                        />
                    </Box>
                    <Box sx={{ pt: 3 }}>
                        <TextField
                            {...register('idea.solution')}
                            fullWidth
                            label={t('solution')}
                            minRows={3}
                            multiline
                            error={!!errors.idea?.solution}
                            helperText={errors.idea?.solution?.message}
                        />
                    </Box>


                    <Box sx={{ pt: 3 }}>
                        <Attachments attachments={attachments} onUpdate={updateAttachments} />
                    </Box>

                </Grid>

                <Grid item xs={12} md={4} >
                    <Box>
                        <CompanyGroupSelect
                            name="idea.companyGroup"
                            label={t('productionGroup')}
                            size="small"
                            control={control}
                            error={!!errors.idea?.companyGroup}
                            helperText={errors.idea?.companyGroup?.message}
                        />
                    </Box>

                    {props.editMode &&
                        <EditFields
                            errors={errors}
                            control={control}
                            register={register}
                            defaultValues={props.defaultValues}
                        />
                    }


                </Grid>

            </Grid>


        </FormTemplate>

    );
}

export default ManageIdea;
