import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CanvasArea from './CanvasArea';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UndoIcon from '@mui/icons-material/Undo';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DrawAttachment(props) {

    const { t } = useTranslation('main')
    const [redraw, setRedraw] = useState(false);
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState('#000000')
    const [clear, setClear] = useState(false)
    const [imgCounter, setImgCounter] = useState(1)
    const canvasRef = useRef(null);
    const historyList = useRef([]);
    
    


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        const canvas = canvasRef.current;

        canvas.toBlob((blob) => {
            const file = new File([blob], `Rysunek_${imgCounter}.png`, { type: 'image/png' });
            setImgCounter(imgCounter + 1)
            props.onSave(file)
            setOpen(false);
        });
    }

    const undoHistory = () => {
        
        if(historyList.current.length>0) {
            historyList.current.pop()
            setRedraw(!redraw)
        }
    }

    useEffect(() => {
        historyList.current = []
    }, [open])

    return (
        <div>
            <Button sx={{ mt: 1 }} variant="outlined" onClick={handleClickOpen} >
                <BrushOutlinedIcon />
                {t('draw')}
            </Button>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>


                        <Typography sx={{ ml: 1, display: { xs: 'none', sm: 'inline-block' } }} variant="h6" component="div">
                            {t('addPicture')}
                        </Typography>


                        <Box sx={{ ml: 2, flex: 1 }} >

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                                <Typography sx={{ ml: 1, display: { xs: 'none', sm: 'inline-block' } }} variant="span" component="span">{t('color')}: </Typography>
                                <Box sx={{ ml: 1 }} >
                                    <input type='color' value={color} onChange={(e) => setColor(e.target.value)}></input>
                                </Box>
                                <Typography sx={{ ml: 5, display: { xs: 'none', sm: 'inline-block' } }} variant="span" component="span">{t('clear')}: </Typography>

                                <Box sx={{ ml: { xs: 5, sm: 2 } }} >

                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => setClear(!clear)}
                                        aria-label="close"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>


                                </Box>
                                <Box sx={{ ml: { xs: 5, sm: 2 } }} >
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => undoHistory()}
                                        aria-label="close"
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>

                        <Button autoFocus color="inherit" onClick={handleSave}>
                            {t('save')}
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box>
                    <CanvasArea color={color} clear={clear} canvasRef={canvasRef} historyList={historyList} redraw={redraw}  />
                </Box>
            </Dialog>
        </div>
    );


}