import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteConfirmButton from "../../components/shared/DeleteConfirmButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { deleteIdea, get } from "../../apis/IdeasApi";
import HorizontalLabel from "../../components/shared/HorizontalLabel";
import SidebarContent from "../../components/shared/SidebarContent";
import VerticalLabel from "../../components/shared/VerticalLabel";
import IdeaAttachments from "./components/IdeaAttachments";
import StatusLabel from "./components/StatusLabel";
import { dateFormat } from "../../components/shared/Utils";

import { useResponseHandler } from "../../hooks/ResponseHandler";
import IsAllowed from "../../components/shared/IsAllowed";



function IdeaPage() {

  let { id } = useParams();

  const { t } = useTranslation('main');
  const [isLoading, setIsLoading] = useState(true)
  const [responseCode, setResponseCode] = useState(0)
  const [data, setData] = useState(null)
  const [handleError, handleSuccess] = useResponseHandler()
  let navigate = useNavigate()


  const onDelete = () => {
    const doDelete = async (ideaId) => {
      await deleteIdea(ideaId).catch((e) => {
        setResponseCode(e.response.status)
        handleError(e)
      }).then((response) => {
        if (response) {
          handleSuccess(response);
          return navigate('/app/ideas')
        }

        setIsLoading(false)
      })

    }

    doDelete(id)
  }

  useEffect(() => {

    const getIdea = async (ideaId) => {
      await get(ideaId).catch((e) => {
        setResponseCode(e.response.status)
      }).then((response) => {
        if (response) {
          setData(response.data)
          setResponseCode(response.status)
        }

        setIsLoading(false)
      })

    }

    getIdea(id)

  }, [])


  return (
    <SidebarContent isLoading={isLoading} responseCode={responseCode}>


      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} alignItems="center">
          <HorizontalLabel label={t('ideaNr')} value={data?.idea?.ideaNr} valueVariant="h5" />
        </Grid>
        <Grid item xs={12} sm={3}><HorizontalLabel label={t('employeeSymbol')} value={data?.idea?.user?.symbol} /></Grid>
        <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' } }}><HorizontalLabel label={t('ideaCreated')} value={data?.idea?.created} /></Grid>
        <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>

          <IsAllowed granted="roleIdeaManageAll">
            <Tooltip title={t('edit')} >
              <IconButton sx={{ mr: 2 }} onClick={() => { return navigate(`/app/ideas/edit/${id}`) }} color="primary" >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>


            <DeleteConfirmButton onDelete={onDelete} />
          </IsAllowed>

        </Grid>
      </Grid>


      <Grid container spacing={3} direction="row" alignItems="stretch">
        <Grid item xs={12} md={8} lg={9} >
          <Paper elevation={2} sx={{ mt: 5, height: '100%' }}  >
            <Grid container sx={{ px: 3, pt: 1, pb: 3 }} spacing={2}>

              <Grid item xs={12}>
                <Typography variant="h3" component="p">{data?.idea?.title} </Typography>
              </Grid>

              <VerticalLabel label={t('issueDescription')} value={data?.idea?.problemDescription} />
              <VerticalLabel label={t('solution')} value={data?.idea?.solution} />

              {data?.idea?.attachments.length > 0 &&
                <Grid item xs={12}>
                  <Typography variant="defaultLabel" component="p">{t('attachments')}</Typography>
                  <IdeaAttachments attachments={data?.idea?.attachments} />
                </Grid>
              }

            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Paper elevation={2} sx={{ mt: 5, height: '100%' }} >
            <Grid container sx={{ px: 3, pt: 1, pb: 3 }} spacing={2}>



              <Grid item xs={12} alignItems="end">
                <StatusLabel status={data?.idea?.status} />
              </Grid>
              <VerticalLabel label={t('productionGroup')} value={data?.idea?.companyGroup?.name} />
              <VerticalLabel label={t('supervisior')} value={((data?.idea?.supervisor) ? data?.idea?.supervisor?.lastName+' '+data?.idea?.supervisor?.firstName : '-')} />
              <VerticalLabel label={t('plannedImplementationDate')} value={dateFormat(data?.idea?.plannedImplementationDate)} />
              <VerticalLabel label={t('implementationDate')} value={dateFormat(data?.idea?.implementationDate)} />
              <VerticalLabel label={t('ideaGroup')} value={data?.idea?.ideaGroup?.name} />
              <VerticalLabel label={t('notes')} value={data?.idea?.notes} />


            </Grid>
          </Paper>
        </Grid>

      </Grid>




    </SidebarContent>

  );
}

export default IdeaPage;
