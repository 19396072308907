import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useUserNotifications } from "../hooks/Notifications";
import { useTranslation } from "react-i18next";

export default function UserNotifications(props) {

    const { t } = useTranslation('main')
    const { allowedNotifications } = useUserNotifications()


    return (
        <>
            <FormGroup>

                {allowedNotifications.map((key) => (
                    <FormControlLabel
                        sx={{ mt: 2 }}
                        {...props.register(`userNotifications.${key}`)}
                        key={key}
                        control={<Checkbox defaultChecked={!!props.defaultValues[key]} />}
                        label={t(`notifications.${key}`)} />
                ))}


            </FormGroup>
            <Box sx={{ pt: 3 }}>
                <Typography variant="light">{t('notificationsRequireEmail')}</Typography>
            </Box>
        </>
    )


}