import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useResponseHandler } from "./../../../../hooks/ResponseHandler";

export default function ManageDialog(props) {

    const { t } = useTranslation('main')
    const [handleError, handleSuccess] = useResponseHandler()

    const exists = (props.editElement) ? props.data.filter((item) => item.id!==props.editElement.id): props.data;
    const existingNames = exists.map(group => group.name.toLowerCase().trim());

    const schema = yup.object({
        name: yup.string()
            .required(t('err.required'))
            .min(2, t('err.min', { min: 2 }))
            .max(99, t('err.max', { max: 99 }))
            .test("unique", t('groupAlreadyExists'), val => !existingNames.includes(val.toLowerCase().trim()))
            .transform((_, val) => val.trim())
    }).required();

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ defaultValues: props.defaultValues, resolver: yupResolver(schema) });

    const clear = () => reset({ name: '' })

    const onClose = () => {
        clear()
        props.close()
    }




    const onSubmit = async (data) => {
        await props.save(data).catch((e) => {
            handleError(e)
        }).then((response) => {

            if (response) {
                handleSuccess(response)
                response && onClose()
                props.updateData(response.data.companyGroup)
            }
        })
    }


    return (
        <Dialog fullWidth scroll="body" maxWidth="xs" open={props.open}  >
            <form onSubmit={handleSubmit(onSubmit)}>


                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <TextField
                                {...register('name')}
                                fullWidth
                                label={t('name')}
                                error={!!errors.name}
                                autoComplete="off"
                                helperText={errors.name?.message}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12} sx={{ px: 1, pb: 1, textAlign: 'right' }}>
                            <Button onClick={onClose} sx={{ mr: 2 }}>{t('cancel')}</Button>
                            <Button variant="contained" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
                        </Grid>
                    </Grid>


                </DialogActions>


            </form>
        </Dialog>
    )


}