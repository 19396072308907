import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { usersList } from '../../../apis/DictionaryApi';



export default function UserSelect(props) {

  
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultOptions] = useState(props.defaultSelected ? [props.defaultSelected]:[])
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const getUsers = async () => {

      const result = await usersList();
      
      if (active && result.status===200) {
        setOptions([...result.data]);
      }

    }
    getUsers()

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) { setOptions([]); }
  }, [open]);



  return (
    <Controller render={({ field: { onChange } }) => (
      <Autocomplete
        fullWidth
        size="small"
        open={open}
        onChange={(event, item) => {
          onChange(item);
        }}
        onOpen={() => { setOpen(true); }}
        onClose={() => { setOpen(false); }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.lastName + ' ' + option.firstName}
        options={options}
        loading={loading}
        defaultValue={props.defaultSelected ? props.defaultSelected:null}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={props.label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )
        }}
      />
    )
    }


      name={props.name}
      control={props.control}

    />

  );
}



