import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SidebarContent from "../../components/shared/SidebarContent";
import SidebarContentTitle from "../../components/shared/SidebarContentTitle";
import { useResponseHandler } from "../../hooks/ResponseHandler";
import { getRequestNotificationsEmail } from "../../apis/SettingsApi";
import Form from "./components/payouts/Form";


export default function PayoutsSettingsPage() {

    const { t } = useTranslation('main')
    const [isLoading, setIsLoading] = useState(true)
    const [responseCode, setResponseCode] = useState()
    const [data, setData] = useState([])
    const [handleError, handleSuccess] = useResponseHandler()


    useEffect(() => {

        const controller = new AbortController();

        const getPointsRanges = async () => {
            await getRequestNotificationsEmail(controller).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                response && setData(response.data.setting)
                setIsLoading(false)
            })
        }

        getPointsRanges()

        return () => controller.abort()

    }, [])

    return (
        <SidebarContent isLoading={isLoading} responseCode={responseCode} noMargins={true} >
            <SidebarContentTitle title={t('settings.payouts')} />



            <Grid container>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ m: 3 }}>
                        <Form data={data} />
                    </Paper>
                </Grid>


            </Grid>
        </SidebarContent>
    )
}