import { Paper, Box, Typography } from '@mui/material';
import { useColumnsDefinition } from './config/config';
import { getList } from '../../../apis/IdeasApi';
import IdeasFilter from '../../ideas/components/IdeasFilters';
import { DataGridContext } from '../../../components/shared/datagrid/DataGridContext';
import ServerDataGrid from '../../../components/shared/datagrid/ServerDataGrid';
import ActiveFilters from '../../../components/shared/form/AciveFilters';
import { useFieldsConfiguration, defaultFilters } from '../../ideas/components/IdeasFilters';
import { useEffect, useContext } from 'react';







export default function IdeasList(props) {


    const [dataGridState, setDataGridContext] = useContext(DataGridContext)



    useEffect(() => {

        const getIdeas = async () => {

            if (!dataGridState.reload)
                return false;

            setDataGridContext({ isLoading: true })
            const result = await getList(dataGridState.limit, dataGridState.page, dataGridState.sort, dataGridState.filters);

            if (result) {
                setDataGridContext({
                    isLoading: false,
                    rows: result.data.data,
                    totalRows: result.data.paginator.totalRecords,
                    reload: false
                })
            } else {
                setDataGridContext({ reload: false })
            }
        }

        getIdeas();

    }, [dataGridState.reload])


    return (
        <>

            <Paper elevation={2} sx={{ ml: 3, mt: 3 }}>

                <Box display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 2, pt: 2 }}>
                    <Typography variant='h6'>{props.header}</Typography>
                    <IdeasFilter />
                </Box>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <ActiveFilters defaultFilters={defaultFilters} fields={useFieldsConfiguration()} />
                </Box>
                <Box >
                    <ServerDataGrid
                        columns={useColumnsDefinition()}

                    />

                </Box>

            </Paper>

        </>
    );
}