import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataGridContextProvider from "../../components/shared/datagrid/DataGridContextProvider";
import PointsHistoryList from "./components/PointsHistoryList";



export default function PointsHistoryPage() {

    const { t } = useTranslation('main')

    return (
        <>
            <Grid container rowSpacing={0} sx={{ pr: 3, pb: 5, m: 0 }}>
                <Grid item xs={12} sx={{ pl: 3, pt: 3 }} >
                    <Typography variant="h1" >{t('pointsHistory')}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <DataGridContextProvider>
                        <PointsHistoryList />
                    </DataGridContextProvider>
                </Grid>
            </Grid>
        </>
    );
}