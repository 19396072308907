import api from "./config/axiosConfig";


export const getList = async (controller) => {
    return await api.get('idea/groups', { signal: controller.signal })
}


export const deleteGroup = async (id) => {
    return await api.delete('idea/groups/' + id)
}


export const create = async (data) => {
    return await api.post('idea/groups', data)
}


export const update = async (id, data) => {
    return await api.put('idea/groups/'+id, data)
}