import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";


export default function SelectFieldElement(props) {

    const { t } = useTranslation('main')

    const labelId = "label-" + props.field.name;

    return (
        <FormControl sx={{ minWidth: '100%' }} size={props.size || 'small'} error={!!props.error}>
            <InputLabel id={labelId}>{props.field.label}</InputLabel>
            <Controller
                name={props.field.name}
                control={props.control}
                render={({ field }) => (
                    <Select labelId={labelId} label={props.field.label} {...field}>
                        <MenuItem value="">
                            <em>{t('choose')}</em>
                        </MenuItem>

                        {props.field.options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        ))}


                    </Select>
                )}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    )
}