import axios from "axios";


const api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,

});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {

        if (error.response.status === 401 && error.config.url !== "login_check") {

            let reloadResult = null

            await axios
                .get(process.env.REACT_APP_API_URL + "token/renew", {
                    withCredentials: true,
                })
                .catch((err) => {

                    reloadResult = false;

                    if (err.response.status !== 401) {
                        return Promise.reject(err);
                    } else {
                        
                        window.location.href = '/logout'
                    }

                }).then((rsp) => {
                    reloadResult = (rsp) ? true : false
                });

            if (reloadResult)
                return axios(error.config);

        } else {
            return Promise.reject(error);
        }
    }
);



export default api;