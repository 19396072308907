import { useState } from 'react';
import { FormHelperText, TextField } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller } from 'react-hook-form';

export default function PasswordElement(props) {


    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({ field }) => (
                <FormControl fullWidth variant="outlined" error={props.error}  >
                    <InputLabel size="small" htmlFor={`pinput-${props.name}`}>{props.label}</InputLabel>
                    <OutlinedInput
                        {...field}
                        id={`pinput-${props.name}`}
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton

                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={props.label}
                        error={props.error}

                    />

                    <FormHelperText error={props.error} >
                        {props.helperText}
                    </FormHelperText>
                </FormControl>

            )} />

    )

  

}