import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pl';


export default function DatePickerLocalizationProvider(props) {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            {props.children}
        </LocalizationProvider>
    )
}