import { Typography } from "@mui/material";

export default function HorizontalLabel(props) {

    return (
        <>
            <Typography variant="defaultLabel" component="span">{props.label}:</Typography>
            <Typography variant={(props.valueVariant ?? 'medium')} component="span"> {props.value || '-'} </Typography>
        </>
    )

}