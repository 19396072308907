import api from "./config/axiosConfig";


export const getOnePointValue = async (controller) => {
    return await api.get('settings/onePointValue', { signal: controller.signal })
}

export const getRequestNotificationsEmail = async (controller) => {
    return await api.get('settings/payoutRequestNotificationsEmail', { signal: controller.signal })
}

export const update = async (data) => {
    return await api.put('settings', data)
}