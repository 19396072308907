import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { download } from '../../../apis/IdeasApi';
import { useResponseHandler } from '../../../hooks/ResponseHandler';



export default function IdeaAttachments(props) {

   
    const [open, setOpen] = useState(false);
    const [handleError] = useResponseHandler()

    

    const handleDownload = (id, filename) => {

        download(id).catch((e) => {
            handleError(e)
            
            setOpen(true);

        }).then((res) => {

            res && fileDownload(res.data, filename)
        })


    }



    return (
        <>
            {props.attachments.map((attachment) => (
                <Box key={attachment.id}>
                    <IconButton onClick={() => { handleDownload(attachment.id, attachment.fileName) }} color="primary">
                        <DownloadingOutlinedIcon />
                    </IconButton>
                    <Typography sx={{ ml: 1 }} component="span" variant="defaultText">{attachment.oryginalFileName}</Typography>
                </Box>
            ))}

            
           
        </>
    )
}