import i18next from 'i18next';

import main_pl from "./translations/pl/main.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'pl',                              // language to use
    resources: {
        pl: {
            main: main_pl
        },
    },
});

export default i18next;


