import { Grid, Typography } from "@mui/material";

export default function VerticalLabel(props) {

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="defaultLabel" component="p">{props.label}</Typography>
                <Typography variant={(props.valueVariant ?? 'defaultText')} component="p"> {props.value || '-'} </Typography>
            </Grid>
        </>
    )

}