
const convertToDateObject = (value) => {


    if (!value) {
        return null;
    }

    if (typeof value === 'string') {
        return new Date(value)
    }
    

    if (typeof value.format === 'function')
        return new Date(value.format('YYYY-MM-DD'))

    return value;


}


export const getBiggerLowerDate = (date1, date2, greather) => {
    date1 = convertToDateObject(date1)
    date2 = convertToDateObject(date2)

    if (date1 && date2) {

        if (greather)
            return (date1 > date2) ? date1 : date2
        else
            return (date1 < date2) ? date1 : date2
    }

    if (date1) {
        return date1
    }

    if (date2) {
        return date2
    }

    return null;


}


export const getDatesFromFilters = (filters) => {
    let startDate = getBiggerLowerDate(filters?.createdFrom, filters?.implementationDateFrom, false)
    let endDate = getBiggerLowerDate(filters?.createdTo, filters?.implementationDateTo, true)
  
    return { startDate: startDate, endDate: endDate }
}

export const getDatesArray = (from, to) => {

    let dates = []

    while (from <= to) {
        dates.push(new Date(from))
        from.setMonth(from.getMonth() + 1)
    }

    return dates;
}

export const getLabelsByDatesArray = (dates) => {
    let labels = []

    for (const index in dates) {
        labels.push((dates[index].getMonth() + 1) + '/' + dates[index].getFullYear())
    }

    return labels
}

export const getDateRangeFromData = (data) => {

    let startDate = null
    let endDate = null

    if (data && data.length > 0) {

        startDate = new Date(data[0].month)
        endDate = new Date(data[0].month)

        for (const item in data) {

            const month = new Date(data[item].month)
            if (startDate > month)
                startDate = month;

            if (endDate < month)
                endDate = month
        }
    }

    return { dataStartDate: startDate, dataEndDate: endDate }
}


export const covertDataToObject = (data) => {
    let dataObj = {}
    for (const index in data) {
        dataObj[data[index].month.slice(0,7)] = data[index].total
    }

    return dataObj;
}

export const getTotal = (data) => {
    let total = 0
    for (const index in data) {
        total += data[index].total
    }
    return total
}