import { useTranslation } from "react-i18next";
import { useFieldsConfiguration } from "./components/ReportFilters";
import ReportPageLayout from "./components/ReportPageLayout";
import ReportByEmployees from "./components/ReportByEmployees";


export default function ReportByEmployeesPage() {

    const { t } = useTranslation('main')  
    

    return (
        <ReportPageLayout
            pageTitle={t('reports.employees')}
            fieldsConfiguration={useFieldsConfiguration()}
        >
            <ReportByEmployees />
        </ReportPageLayout>
    );
}