import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../components/shared/AuthContext';
import FilterForm from "../../../components/shared/form/FilterForm";


export const useFieldsConfiguration = () => {

    const { t } = useTranslation('main')
    const [state] = useContext(AuthContext)


    return [
        { type: 'text', name: 'ideaNr', label: t('ideaNr') },
        { type: 'text', name: 'title', label: t('ideaTitle') },
        { type: 'text', name: 'symbol', label: t('employeeSymbol') },
        { type: 'select', name: 'companyGroup', label: t('productionGroup'), options: state.dictionary.companyGroups },
        { type: 'select', name: 'status', label: t('status'), options: state.dictionary.ideaStatuses },
        { type: 'select', name: 'ideaGroup', label: t('ideaGroup'), options: state.dictionary.ideaGroups },
        {
            type: 'fromtogroup',
            name: 'createdGroup',
            fromField: { type: 'date', name: 'createdFrom', label: t('ideaCreatedFrom') },
            toField: { type: 'date', name: 'createdTo', label: t('to') },


        },
        {
            type: 'fromtogroup',
            name: 'deployedGroup',
            fromField: { type: 'date', name: 'implementationDateFrom', label: t('ideaDeployedFrom') },
            toField: { type: 'date', name: 'implementationDateTo', label: t('to') },


        },
    ];
}


export const defaultFilters = {

    companyGroup: '',
    status: '',
    ideaGroup: '',
    createdFrom: null,
    createdTo: null,
    implementationDateFrom: null,
    implementationDateTo: null,

}


export default function IdeasFilter(props) {

    return (
        <FilterForm
            fields={useFieldsConfiguration()}
            defaultFilters={defaultFilters}

        />
    );
}