import { Box, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ManageDialog from "./ManageDialog"
import { create } from "../../../../apis/IdeasGroupsApi"


export default function AddForm(props) {

    const { t } = useTranslation('main')
    const [open, setOpen] = useState(false)
   
    return (
        <>
            <Box sx={{ py: 1, px: 1 }} textAlign="right">
                <Button onClick={() => setOpen(true)}>{t('addNewGroup')}</Button>
            </Box>
            <ManageDialog
                open={open}
                close={() => setOpen(false)}
                dialogTitle={t('addNewGroup')}
                defaultValues={{ name: '' }}
                save={create}
                updateData={props.updateData}
                data={props.data}
            />
        </>
    )
}