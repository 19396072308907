import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import SelectFieldElement from "../form/element/SelectFieldElement";

export default function StatusSelect(props) {
    const [state] = useContext(AuthContext)

    const field = {
        name: props.name,
        label: props.label,
        options: state.dictionary.ideaStatuses,
       
    }

        
    return (<SelectFieldElement
        field={field}
        control={props.control}
        size={props.size || 'medium'}
        error={props.error}
        helperText={props.helperText} 

    />)

}