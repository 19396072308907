import { Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import StatusSelect from "../../../components/shared/input/StatusSelect";
import IdeaGroupsSelect from "../../../components/shared/input/IdeaGroupsSelect";
import DateFieldElement from "../../../components/shared/form/element/DateFieldElement";
import DatePickerLocalizationProvider from "../../../components/shared/DatePickerLocalizationProvider";
import UserSelect from "../../../components/shared/input/UserSelect";



export default function EditFields(props) {

   

    const { t } = useTranslation('main')

    return (
        <>
            <DatePickerLocalizationProvider>
                <Box sx={{ pt: 3 }}>
                    <StatusSelect
                        name="idea.status"
                        label={t('status')}
                        size="small"
                        control={props.control}
                        error={!!props.errors.idea?.status}
                        helperText={props.errors.idea?.status?.message}
                    />
                </Box>
                <Box sx={{ pt: 3 }}>
                    <IdeaGroupsSelect
                        name="idea.ideaGroup"
                        label={t('ideaGroup')}
                        size="small"
                        control={props.control}
                        error={!!props.errors.idea?.ideaGroup}
                        helperText={props.errors.idea?.ideaGroup?.message}
                    />
                </Box>

                <Box sx={{ pt: 3 }}>
                    <TextField
                        {...props.register('idea.pointsForAdd')}
                        fullWidth
                        size="small"
                        label={t('pointsForAdd')}
                        error={!!props.errors.idea?.pointsForAdd}
                        helperText={props.errors.idea?.pointsForAdd?.message} />
                </Box>


                <Box sx={{ pt: 3 }}>
                    <UserSelect
                    control={props.control}
                    label={t('supervisior')}
                    name="idea.supervisor"
                    defaultSelected={props.defaultValues.idea.supervisor}
                    />
                </Box>


                {/* <Box sx={{ pt: 3 }}>
                    <TextField
                        {...props.register('idea.supervisorName')}
                        fullWidth
                        size="small"
                        label={t('supervisior')}
                        error={!!props.errors.idea?.supervisorName}
                        helperText={props.errors.idea?.supervisorName?.message} />
                </Box> */}
                <Box sx={{ pt: 3 }}>
                    <DateFieldElement
                        control={props.control}
                        field={{
                            name: 'idea.plannedImplementationDate',
                            label: t('plannedImplementationDate')
                        }}
                    />
                </Box>


                <Box sx={{ pt: 3 }}>
                    <DateFieldElement
                        control={props.control}
                        field={{
                            name: 'idea.implementationDate',
                            label: t('implementationDate'),
                            maxDate: new Date()
                        }}
                    />
                </Box>

                <Box sx={{ pt: 3 }}>
                        <TextField
                            {...props.register('idea.notes')}
                            fullWidth
                            label={t('notes')}
                            minRows={3}
                            multiline
                            error={!!props.errors.idea?.notes}
                            helperText={props.errors.idea?.solution?.notes}
                        />
                    </Box>

            </DatePickerLocalizationProvider>
        </>


    )
}