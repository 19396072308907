import { Chip } from "@mui/material";

export default function PointsLabel(props) {

    let color = 'default';
    switch (props.type) {
        case 'deployed':
            color = 'success';
            break;
        case 'new':
            color = 'default';
            break;
    }

    return (<Chip size="small" label={`+${props.points}`} color={color} />)

}