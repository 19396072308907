import { LoadingButton } from "@mui/lab";
import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SidebarContent from "../SidebarContent";

export default function FormTemplate(props) {

    const { t } = useTranslation('main')

  

    return (
        <SidebarContent responseCode={props.responseCode} isLoading={!!props.contentIsLoading} >
            <form onSubmit={props.handleSubmit} >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={props.headerChildren ? 4 : 12} sx={{ pb: 2 }} >

                        <Typography variant="h1" >{props.formTitle}</Typography>

                    </Grid>
                    {props.headerChildren &&
                        <Grid item xs={12} md={8} sx={{ pb: 2 }} >
                            {props.headerChildren}
                        </Grid>
                    }
                </Grid>


                <Grid container spacing={3} direction="row" alignItems="stretch">
                    <Grid item xs={12}  >
                        <Paper elevation={2} sx={{ mt: 5, height: '100%' }}  >
                            {props.children}

                            <Grid container sx={{ px: 3, pb: 5 }} justifyContent="flex-end" >
                                <Grid item xs={12} sm={4} >
                                    <LoadingButton type="submit"
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        loading={props.isLoading}
                                        sx={{ ml: 'auto' }}
                                        {...props.btnOptions}
                                    >
                                        {(props?.btnTitle) ? props.btnTitle : t('save')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>


                        </Paper>
                    </Grid>



                </Grid>

            </form>


        </SidebarContent>

    );
}