import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"
import { Paper, Grid, Typography, Button } from "@mui/material"

export default function PaperButton(props) {

    const { t } = useTranslation('main');
    const navigate = useNavigate()

    return (
        <Paper elevation={2} sx={{ ml: 3, height: '100%' }}>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={9}>
                    <Typography variant="h6">{props.title}</Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    {props.icon}
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right', pt: 3 }}>
                    <Button onClick={() => navigate(props.url)} variant="outlined">{(props.btnTitle) ? props.btnTitle : t('edit')}</Button>
                </Grid>
            </Grid>


        </Paper>
    )
}