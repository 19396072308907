import { DataGrid } from "@mui/x-data-grid";
import { useContext } from "react";
import { DataGridContext } from "./DataGridContext";
import { useDataGridTranslation } from "./DataGridTranslations";



export default function ServerDataGrid(props) {

    const [dataGridState, setDataGridContext] = useContext(DataGridContext)



    return (

        <>

            <DataGrid
                autoHeight
                localeText={useDataGridTranslation()}
                rowsPerPageOptions={[5, 10, 25, 50]}
                disableColumnFilter
                paginationMode="server"
                getRowHeight={() => 'auto'}
                getCellClassName={() => {
                    return 'standard-cell'
                  }}
                pagination
                sortingMode="server"
                sortModel={dataGridState.sort}
                page={dataGridState.page - 1}
                loading={dataGridState.isLoading}
                rows={dataGridState.rows}
                rowCount={dataGridState.totalRows}
                pageSize={dataGridState.limit}
                onPageSizeChange={(newPageSize) => setDataGridContext({ limit: newPageSize })}
                onPageChange={(newPage) => {
                    setDataGridContext({ page: (newPage + 1) })
                }}
                onSortModelChange={(model) => setDataGridContext({ sort: model })}
                {...props}



            />
        </>
    )
}