import { Grid } from "@mui/material"
import { t } from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import FullPageLoader from "./FullPageLoader"
import NotFound from "./NotFound"


export default function SidebarContent(props) {

    const { t } = useTranslation('main')

   

    if (props.isLoading) {
        return (<FullPageLoader />)
    }

    if (props.responseCode === 404) {
        return (<NotFound />)
    }



    return (
        <>
            <Grid container sx={(props.noMargins) ? {} : { px: { xs: 1, sm: 3 }, py: 3 }} >
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </>

    )
}