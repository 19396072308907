import { privileges } from '../../apis/AuthApi';
import { AuthContext } from './AuthContext';
import { useContext, useState, useEffect } from 'react';

export const useIsAllowed = () => {

    const [state] = useContext(AuthContext)

    const includeAll = (grantedList) => {
        return grantedList.every(i => state.privileges.includes(i));
    }

    const isAllowed = (granted) => {
        if (!granted)
            return false;

        if (typeof granted === 'string' && !state.privileges.includes(granted)) {
            return false;
        }

        if (Array.isArray(granted) && !includeAll(granted)) {
            return false;
        }
        return true;
    }

    return isAllowed;

}


export default function IsAllowed(props) {

    const [state] = useContext(AuthContext);
    const [isGranted, setIsGranted] = useState(false)


    useEffect(() => {

        if (Array.isArray(state?.privileges) && props.granted && state.privileges.includes(props.granted)) {
            setIsGranted(true)
        }

    }, [])


    if (!isGranted)
        return (<></>)

    return (
        <>
            {props.children}
        </>
    )
}