import { Box, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getRank } from '../../../apis/PointsHistoryApi';
import { DataGridContext } from '../../../components/shared/datagrid/DataGridContext';
import ServerDataGrid from '../../../components/shared/datagrid/ServerDataGrid';
import ActiveFilters from '../../../components/shared/form/AciveFilters';
import { defaultFilters, useFieldsConfiguration } from '../../points-rank/components/PointsRankFilters';
import { useColumnsDefinition } from './config/config';
import PoinntsRankFilters from './PointsRankFilters';







export default function PointsRankList(props) {

    const { t } = useTranslation('main')

    const [dataGridState, setDataGridContext] = useContext(DataGridContext)
    const [totalPoints, setTotalPoints] = useState(null)



    useEffect(() => {

        const getPointsRank = async () => {

            

            if (!dataGridState.reload)
                return false;

            setDataGridContext({ isLoading: true })
            const result = await getRank(dataGridState.limit, dataGridState.page, dataGridState.sort, dataGridState.filters)

            if (result) {
                setTotalPoints(result.data.pointsSum)

                setDataGridContext({
                    isLoading: false,
                    rows: result.data.results.data,
                    totalRows: result.data.results.paginator.totalRecords,
                    reload: false
                })
            } else {
                setDataGridContext({ reload: false })
            }
        }

        getPointsRank();

    }, [dataGridState.reload])



    return (
        <>

            <Paper elevation={2} sx={{ ml: 3, mt: 3 }}>

                <Box display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 2, pt: 2 }}>

                    <Box sx={{ pb: 2}}>
                    <ActiveFilters defaultFilters={defaultFilters} fields={useFieldsConfiguration()} />
                    </Box>
                    <Box sx={{ pb: 2}}>
                    <PoinntsRankFilters />
                    </Box>
                </Box>
                
                <Box >
                    <ServerDataGrid
                        columns={useColumnsDefinition()}

                    />

                </Box>

            </Paper>

        </>
    );
}