import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';


export default function FullPageLoader(props) {

    return (<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: (props.vh ? '100vh':'calc(100% - 80px)' )}}
    >

        <Grid item xs={3}>
            <CircularProgress />
        </Grid>

    </Grid>)

}