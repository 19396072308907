import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { update } from "../../../../apis/SettingsApi";
import { useResponseHandler } from "./../../../../hooks/ResponseHandler";

export default function Form(props) {

    const { t } = useTranslation('main')
    const [isLoading, setIsLoading] = useState(false)
    const [handleError, handleSuccess] = useResponseHandler()

    const schema = yup.object({
        payoutRequestNotificationsEmail: yup.string()
            .email(t('err.emailInvalid'))
            .required(t('err.required'))
    })
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ defaultValues: { payoutRequestNotificationsEmail: props.data.settingValue }, resolver: yupResolver(schema) })

    const onSubmit = (formData) => {
        setIsLoading(true)

        const updateSetting = async (data) => {
            await update(data).catch((e) => {
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response)
                }
                setIsLoading(false)
            })
        }

        updateSetting(formData)
    }

    return (
        <Grid container>
            <Grid item xs={12} >
                <Typography variant="h6" sx={{ p: 2 }}>{t('payoutsSendTo')}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ px: 2, pb: 3 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        {...register('payoutRequestNotificationsEmail')}
                        fullWidth
                        label={t('email')}
                        size="small"
                        error={!!errors.payoutRequestNotificationsEmail}
                        helperText={errors.payoutRequestNotificationsEmail?.message}

                    />

                    <Box textAlign="right" sx={{ pt: 3 }}>
                        <LoadingButton loading={isLoading} onClick={handleSubmit(onSubmit)} variant="contained">{t('save')}</LoadingButton>
                    </Box>
                </form>
            </Grid>
        </Grid>
    )

}