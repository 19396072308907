import api from "./config/axiosConfig";



export const getMyPoints = async (controller) => {

    return await api.get('points/my', { signal: controller.signal })

}

export const getPointsValue = async (data, controller) => {

    return await api.post('points/value', data, { signal: controller.signal })

}

export const updateSaldo = async (id, data) => {
    return await api.put('saldo/'+id, data)
}


