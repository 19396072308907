import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormTemplate from "../../../components/shared/form/FormTemplate";
import CompanyGroupSelect from "../../../components/shared/input/CompanyGroupSelect";
import { useResponseHandler } from "../../../hooks/ResponseHandler";
import AccountStatusesSelect from "./input/AccountStatusSelect";
import { getList } from "../../../apis/RolesApi"
import SelectFieldElement from "../../../components/shared/form/element/SelectFieldElement"
import PrintCredentialsDialog from "./PrintCredentialsDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import PasswordInputs from "./PasswordInputs";
// import EditFields from "./EditFields";




function ManageUser(props) {



    const { t } = useTranslation('main');
    const [isLoading, setIsLoading] = useState(false)
    const [printCredentialsOpen, setPrintCredentialsOpen] = useState(false)
    const [changePasswordOpen, setChangePasswordOpen] = useState(false)
    const [credentials, setCredentials] = useState(null)
    const [roles, setRoles] = useState([])
    const [responseCode, setResponseCode] = useState(0)
    const [handleError, handleSuccess] = useResponseHandler()

    const { register, control, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm({ defaultValues: props.defaultValues, resolver: yupResolver(props.schema) });

    const onSubmit = (formData) => {

        setIsLoading(true)

        const save = async (params) => {
            await props.save(params).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response);

                    if (params.password) {
                        setCredentials(params)
                        setPrintCredentialsOpen(true)
                    } else {
                        props.onSuccess()
                    }
                }

                setIsLoading(false)
            })
        }

        save(formData)

    }


    const onPasswordChanged = (data) => {
        setChangePasswordOpen(false)
        setPrintCredentialsOpen(true)
        setCredentials({ ...props.defaultValues, ...data })
    }


    let headerChildren = null;




    useEffect(() => {
        const controller = new AbortController();

        const getRoles = async () => {
            await getList(controller).catch((e) => {
                handleError(e);
            }).then((response) => {
                if (response) {
                    setRoles(response.data);
                }
            })
        }

        getRoles();

        return () => controller.abort();
    }, [])


    return (
        <FormTemplate
            formTitle={props.formTitle}
            responseCode={responseCode}
            handleSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            headerChildren={headerChildren}
            contentIsLoading={!roles.length}
        >

            <Grid container sx={{ px: 3, pt: 0, pb: 3 }} spacing={5} >
                <Grid item xs={12} md={4} >


                    <Box >
                        <TextField
                            {...register('username')}
                            fullWidth
                            autoComplete="off"
                            size="small"
                            label={t('username')}
                            error={!!errors.username}
                            helperText={errors.username?.message} />
                    </Box>

                    {props.editMode &&
                        <>
                            <ChangePasswordDialog
                                open={changePasswordOpen}
                                onClose={() => setChangePasswordOpen(false)}
                                onPasswordChanged={onPasswordChanged}
                                userId={props.defaultValues.id}
                            />
                            <Box sx={{ pt: 3 }}>
                                <Button onClick={() => setChangePasswordOpen(true)} variant="outlined">{t('changePassword')}</Button>
                            </Box>
                        </>
                    }

                    {!props.editMode &&
                        <>
                            <PasswordInputs
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                        </>
                    }
                    <Box sx={{ pt: 3 }}>
                        <AccountStatusesSelect
                            name="status"
                            label={t('status')}
                            size="small"
                            control={control}
                            error={!!errors.status}
                            helperText={errors.status?.message}
                        />
                    </Box>

                    <Box sx={{ pt: 3 }}>
                        <SelectFieldElement
                            field={{
                                name: 'role',
                                label: t('role'),
                                options: roles
                            }}
                            control={control}
                            error={!!errors.role}
                            helperText={errors.role?.message}

                        />
                    </Box>

                </Grid>




                <Grid item xs={12} md={4} >

                    <Box>
                        <TextField
                            {...register('symbol')}
                            fullWidth
                            autoComplete="off"
                            size="small"
                            label={t('employeeSymbol')}
                            error={!!errors.symbol}
                            helperText={errors.symbol?.message} />
                    </Box>

                    <Box sx={{ pt: 3 }}>
                        <TextField
                            {...register('firstName')}
                            fullWidth
                            autoComplete="off"
                            size="small"
                            label={t('firstName')}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message} />
                    </Box>

                    <Box sx={{ pt: 3 }}>
                        <TextField
                            {...register('lastName')}
                            fullWidth
                            autoComplete="off"
                            size="small"
                            label={t('lastName')}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message} />
                    </Box>

                    <Box sx={{ pt: 3 }}>
                        <CompanyGroupSelect
                            name="companyGroup"
                            label={`${t('productionGroup')} (${t('optional')})`}
                            size="small"
                            control={control}
                            error={!!errors.companyGroup}
                            helperText={errors.companyGroup?.message}
                        />
                    </Box>

                    <Box sx={{ pt: 3 }}>
                        <TextField
                            {...register('email')}
                            fullWidth
                            autoComplete="off"
                            size="small"
                            label={`${t('email')} (${t('optional')})`}
                            error={!!errors.email}
                            helperText={errors.email?.message} />
                    </Box>
                </Grid>


            </Grid>

            <PrintCredentialsDialog
                open={printCredentialsOpen}
                onFinish={props.defaultValues.id ? () => setPrintCredentialsOpen(false) : props.onSuccess}
                credentials={credentials}
            />
        </FormTemplate >

    );
}

export default ManageUser;
