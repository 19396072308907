import { Grid, Typography } from "@mui/material"

export default function SidebarContentTitle(props) {
    return (
        <Grid container>
            <Grid item xs={12} sx={{ px: 3, pt: 3 }} >
                <Typography variant="h1" >{props.title}</Typography>
            </Grid>
        </Grid>
    )
}