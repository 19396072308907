import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@mui/material";
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { updatePassword } from "../../apis/ProfileApi";
import FormTemplate from "./../../components/shared/form/FormTemplate";
import { useResponseHandler } from "./../../hooks/ResponseHandler";

export default function PasswordPage() {

    const { t } = useTranslation('main');

    const schema = yup.object({
        password: yup.string()
            .required(t('err.required'))
            .min(6, t('err.min', { min: 6 }))
            .max(50, t('err.max', { max: 50 })),
        confirmPassword: yup.string()
            .required(t('err.required'))
            .oneOf([yup.ref('password'), null], t('err.passwordIsNotTheSame'))
            .min(6, t('err.min', { min: 6 }))
            .max(50, t('err.max', { max: 50 })),
    }).required();



    const [handleError, handleSuccess] = useResponseHandler()
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });


    const [isLoading, setIsLoading] = useState(false)
    const [responseCode, setResponseCode] = useState(0)

    let navigate = useNavigate();



    const onSubmit = (formData) => {
        setIsLoading(true)


        const save = async (params) => {
            await updatePassword(params).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response);
                    return navigate('/app/dashboard');

                }

                setIsLoading(false)
            })
        }

        save(formData)
    }




    return (

        <Grid container>


            <Grid item xs={12} md={6} lg={4} >
                <FormTemplate
                    formTitle={t('changePassword')}
                    responseCode={responseCode}
                    handleSubmit={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >

                    <Grid container sx={{ px: 3, pt: 0, pb: 3 }} spacing={5} >


                        <Grid item xs={12} >



                            <TextField
                                {...register('password')}
                                fullWidth
                                autoComplete="off"
                                label={t('newPassword')}
                                type="password"
                                error={!!errors.password}
                                helperText={errors.password?.message} />


                        </Grid>

                        <Grid item xs={12} >



                            <TextField
                                {...register('confirmPassword')}
                                fullWidth
                                autoComplete="off"
                                label={t('confirmPassword')}
                                type="password"
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword?.message} />


                        </Grid>



                    </Grid>

                </FormTemplate>
            </Grid>
        </Grid>

    )
}