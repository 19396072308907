import { Box, Button } from "@mui/material";
import PasswordElement from "../../../components/shared/form/element/PasswordElement";
import { useTranslation } from "react-i18next";
import { generatePassword } from "../../../components/shared/Utils";

export default function PasswordInputs(props) {

    const { t } = useTranslation('main')


    const handleGeneratePassClick = () => {
        const pwd = generatePassword();
        props.setValue('password', pwd)
        props.setValue('confirmPassword', pwd)
        props.clearErrors('password')
        props.clearErrors('confirmPassword')

    }


    return (
        <>

            <Box sx={{ textAlign: 'right', pt: 1 }}>
                <Button variant="text" onClick={handleGeneratePassClick}>{t('generatePassword')}</Button>
            </Box>
            <Box sx={{ pt: 1 }}>
                <PasswordElement
                    name="password"
                    control={props.control}
                    label={t('password')}
                    error={!!props.errors.password}
                    helperText={props.errors.password?.message}
                />
            </Box>




            <Box sx={{ pt: 3 }}>
                <PasswordElement
                    name="confirmPassword"
                    control={props.control}
                    label={t('repassword')}
                    error={!!props.errors.confirmPassword}
                    helperText={props.errors.confirmPassword?.message}
                />
            </Box>
        </>
    )

}