import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserFormValidationSchema } from "./components/config/ValidationSchema";
import ManageUser from "./components/ManageUser";
import { get, update } from "../../apis/UsersApi";
import { useNavigate, useParams } from "react-router-dom";
import SidebarContent from "../../components/shared/SidebarContent";
import { useResponseHandler } from "../../hooks/ResponseHandler";
import { flatObject, nullToEmptyString } from "../../components/shared/Utils";

function EditUserPage() {

  let { id } = useParams();

  const { t } = useTranslation('main')
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [responseCode, setResponseCode] = useState(0)
  const [data, setData] = useState({})
  const [handleError] = useResponseHandler()

  const preparaDataFromApi = (data) => {
    return nullToEmptyString(flatObject(data), [])
  }


  const onSuccess = () => {
    navigate('/app/users')
  }


  useEffect(() => {

    const getUser = async (userId) => {
      await get(userId).catch((e) => {
        setResponseCode(e.response.status)
        handleError(e)
      }).then((response) => {
        if (response) {
          setData(preparaDataFromApi(response.data.user))
        }

        setIsLoading(false)
      })

    }

    getUser(id)

  }, [])

  return (
    <SidebarContent isLoading={isLoading} responseCode={responseCode}>
      <ManageUser
        formTitle={`${t('editUser')} - ${data.username}`}
        schema={useUserFormValidationSchema('edit', data)}
        defaultValues={data}
        save={(dataToSave) => { return update(id, dataToSave) }}
        onSuccess={onSuccess}
        editMode={true}

      />
    </SidebarContent>

  );
}

export default EditUserPage;
