import api from "./config/axiosConfig";
import { buildQuery } from "./utils/QueryBuilder";


export const getList = async (limit, page, sort, filters) => {
    return await api.get('users' + buildQuery(limit, page, sort, filters))
}

export const get = async (id) => {
    return await api.get('users/' + id)

}

export const deleteUser = async (id) => {
    return await api.delete('users/' + id)
}

export const create = async (data) => {
    return await api.post('users', data)

}

export const update = async (id, data) => {
    return await api.put('users/' + id, data)

}

export const exists = async (username, controller) => {
    return await api.get('user-exists?username=' + username, { signal: controller.signal })
}

export const symbolExists = async (symbol, controller) => {
    return await api.get('symbol-exists?symbol=' + symbol, { signal: controller.signal })
}

export const downloadCredentials = async (data) => {
    return api.post('pdf/credentials', data, {
        responseType: 'blob',
    })
}

export const download = async (data) => {

    
    return api.get('users-to-excel'+ buildQuery(null, null, null, data), {
        responseType: 'blob',
    })
}

export const updatePassword = async (id, data) => {
    return await api.put('user-password/' + id, data)
}


