import { useEffect, useState } from "react";
import FormTemplate from "../../components/shared/form/FormTemplate";
import { getProfile, updateProfile } from "../../apis/ProfileApi";
import { useResponseHandler } from "../../hooks/ResponseHandler";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid, TextField } from "@mui/material";
import CompanyGroupSelect from "../../components/shared/input/CompanyGroupSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserFormValidationSchema } from "../users/components/config/ValidationSchema";
import UserNotifications from "./components/UserNotifications";

export default function MyProfilePage() {

    const { t } = useTranslation('main')

    const [isLoading, setIsLoading] = useState(true)
    const [responseCode, setResponseCode] = useState(0)
    const [data, setData] = useState(null)
    const [handleError, handleSuccess] = useResponseHandler()

    const { register, control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(useUserFormValidationSchema('myprofile')),
        defaultValues: {
            user: { firstName: '', lastName: '', companyGroup: '', email: '' }
        }
    });


    const setUpDefaults = (responseData) => {
        const defaultData = {
            user: {
                firstName: responseData.user?.firstName,
                lastName: responseData.user?.lastName,
                companyGroup: (responseData.user?.companyGroup?.id) ? responseData.user?.companyGroup?.id:'',
                email: responseData.user?.email
            },
            userNotifications: responseData.user.notifications
        }
        reset(defaultData)
        setData(defaultData)
    }

    useEffect(() => {

        const controller = new AbortController()

        const getMyProfile = async () => {
            await getProfile(controller).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {

                response && setUpDefaults(response.data)

                setIsLoading(false)
            })
        }

        getMyProfile()

        return () => controller.abort()
    }, [])


    const onSubmit = (data) => {
        setIsLoading(true)
        const updateMyProfile = async (formData) => {
            await updateProfile(formData).catch((e)=> {
                handleError(e)
            }).then((response)=>{
                handleSuccess(response)
                setIsLoading(false)
            })
        }

        updateMyProfile(data)

    }


    return (


        <FormTemplate
            formTitle={t('myProfile')}
            responseCode={responseCode}
            handleSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            contentIsLoading={!data && isLoading}
        >

            {data && <>
                <Grid container sx={{ px: 3, pt: 0, pb: 3 }} spacing={5} >


                    <Grid item xs={12} md={5} >
                        <Box sx={{ pt: 3 }}>
                            <TextField
                                {...register('user.firstName')}
                                fullWidth
                                autoComplete="off"
                                size="small"
                                label={t('firstName')}
                                error={!!errors.user?.firstName}
                                helperText={errors.user?.firstName?.message} />
                        </Box>

                        <Box sx={{ pt: 3 }}>
                            <TextField
                                {...register('user.lastName')}
                                fullWidth
                                autoComplete="off"
                                size="small"
                                label={t('lastName')}
                                error={!!errors.user?.lastName}
                                helperText={errors.user?.lastName?.message} />
                        </Box>

                        <Box sx={{ pt: 3 }}>
                            <CompanyGroupSelect
                                name="user.companyGroup"
                                label={`${t('productionGroup')} (${t('optional')})`}
                                size="small"
                                control={control}
                                error={!!errors.user?.companyGroup}
                                helperText={errors.user?.companyGroup?.message}
                            />
                        </Box>

                        <Box sx={{ pt: 3 }}>
                            <TextField
                                {...register('user.email')}
                                fullWidth
                                autoComplete="off"
                                size="small"
                                label={`${t('email')} (${t('optional')})`}
                                error={!!errors.user?.email}
                                helperText={errors.user?.email?.message} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={7} >
                        <UserNotifications register={register} defaultValues={data.userNotifications} />
                    </Grid>


                </Grid>
            </>
            }

        </FormTemplate >

    );
}