import { Box, Grid, Paper, Typography } from "@mui/material";
import DataGridContextProvider from "../../../components/shared/datagrid/DataGridContextProvider";
import ActiveFilters from "../../../components/shared/form/AciveFilters";
import ReportFilters from "./ReportFilters";


export default function ReportPageLayout(props) {

    

    return (
        <>
            <DataGridContextProvider>
                <Grid container rowSpacing={0} sx={{ pr: 3, pb: 5, m: 0 }}>
                    <Grid item xs={12} sm={6} sx={{ pl: 3, pt: 3 }} >
                        <Typography variant="h1" >{props.pageTitle}</Typography>
                    </Grid>
                    <Grid item xs={12}>

                        <Paper elevation={2} sx={{ ml: 3, mt: 3 }}>
                            <Box display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 2, pt: 2 }}>
                                <Box>
                                    <ActiveFilters fields={props.fieldsConfiguration} />
                                </Box>
                                <Box>
                                    <ReportFilters />
                                </Box>
                            </Box>
                            <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                                {props.children}
                            </Box>


                        </Paper>


                    </Grid>
                </Grid>
            </DataGridContextProvider>
        </>

    );
}