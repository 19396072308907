import { useTranslation } from "react-i18next";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Tooltip, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IsAllowed from "../../../../components/shared/IsAllowed";
import UserStatusLabel from "../UserStatusLabel";


const css = { align: 'center', headerAlign: 'center' }
const sortingOrder = { sortingOrder: ['desc', 'asc'] }

export const useColumnsDefinition = () => {

    const { t } = useTranslation('main')
    let navigate = useNavigate()

    const handleTitleClick = (params) => {
        return navigate('/app/users/' + params.id)
    }

    const handleEditClick = (params) => {
        return navigate('/app/users/edit/' + params.id)
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, ...css, ...sortingOrder, },
        {
            field: 'username',
            flex: 1,
            headerName: t('username'),
            minWidth: 190,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <Link sx={{ cursor: 'pointer' }} onClick={() => handleTitleClick(params.row)} >{params.row.username}</Link>
            )
        },
        {
            field: 'lastName',
            headerName: t('fullname'),
            minWidth: 190,
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.lastName} ${params.row.firstName}`;
            },
            ...css,
            ...sortingOrder,
        },
        { field: 'symbol', headerName: t('employeeSymbol'), width: 140, ...css, ...sortingOrder, },
        {
            field: 'r.name',
            headerName: t('accountType'),
            minWidth: 190,
            valueGetter: (params) => params.row.role.name,
            ...css,
            ...sortingOrder,
        },
        {
            field: 'cg.name',
            headerName: t('productionGroup'),
            width: 140,
            valueGetter: (params) => params.row.companyGroup?.name,
            ...css,
            ...sortingOrder,
        },
        {
            field: 'status',
            headerName: t('status'),
            width: 140,
            renderCell: (params) => (<UserStatusLabel status={params.row.status} />),
            ...css,
            ...sortingOrder,
        },

        {
            field: 'actions',
            headerName: t('edit'),
            sortable: false,
            disableColumnMenu: true,
            width: 80,
            renderCell: (params) => (
                <IsAllowed granted="roleUsersManage">
                    <Tooltip title={t('edit')} placement="left" >
                        <IconButton onClick={() => handleEditClick(params.row)} color="primary" >
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </IsAllowed>
            ),

            ...css,
            ...sortingOrder,
        },
    ];

    return columns;
}