import { useContext, useEffect, useRef, useState } from "react";
import { DataGridContext } from "../../../components/shared/datagrid/DataGridContext";
import FullPageLoader from "../../../components/shared/FullPageLoader";
import { getByUser } from "../../../apis/ReportApi";
import { useResponseHandler } from "../../../hooks/ResponseHandler";
import { getByEmployeesData, getTotal } from "../data-converters/byemployees";
import { useTranslation } from "react-i18next";
import { Alert, Box, Typography } from "@mui/material";
import VerticalBarChart from "./charts/VerticalBarChart";



export default function ReportByEmployees() {

    const { t } = useTranslation('main')

    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [chartData, setChartData] = useState({ data: null, options: null })
    const [dataGridState, setDataGridContext] = useContext(DataGridContext)
    const [handleError] = useResponseHandler()





    let controller = useRef()

    useEffect(() => {


        const getChartData = async () => {

            if (!dataGridState.reload)
                return false;

            setIsLoading(true)

            controller.current?.abort()
            controller.current = new AbortController()

            await getByUser(dataGridState.filters, controller.current).catch((e) => {
                handleError(e)
            }).then((response) => {
                if (response) {
                    const chartResult = getByEmployeesData(response.data, dataGridState.filters)
                    setChartData(chartResult)
                    setTotal(getTotal(response.data))
                }
                setDataGridContext({ reload: false })
                setIsLoading(false)
            })

        }

        getChartData()

        return () => controller.current?.abort()


    }, [dataGridState.reload])


    if (isLoading) {
        return (<FullPageLoader />)
    }

    if (chartData.data === null) {
        return (
            <Alert sx={{ mt: 3, mb: 3 }} severity="info">
                {t('noResults')}
            </Alert>)
    }

 

    return (

        <>
            <Box sx={{ pt: 1, textAlign: 'right' }}>
                <Typography>{t('ideasSum')}: {total}</Typography>
            </Box>
            <Box sx={{ height: '400px' }}>
                <VerticalBarChart options={chartData.options} data={chartData.data} />
            </Box>
        </>
    )

}