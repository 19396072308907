import { useUserStatuses } from "../hooks/statuses"
import SelectFieldElement from "../../../../components/shared/form/element/SelectFieldElement"

export default function AccountStatusesSelect(props) {

     const {statuses} = useUserStatuses()

    const field = {
        name: props.name,
        label: props.label,
        options: statuses

    }


    return (<SelectFieldElement
        field={field}
        control={props.control}
        size={props.size || 'medium'}
        error={props.error}
        helperText={props.helperText}

    />)

}