import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../components/shared/AuthContext';
import FilterForm from "../../../components/shared/form/FilterForm";


export const useFieldsConfiguration = () => {

    const { t } = useTranslation('main')
    const [state] = useContext(AuthContext)


    return [
        { type: 'text', name: 'ideaNr', label: t('ideaNr') },
        { type: 'text', name: 'symbol', label: t('employeeSymbol') },
        { type: 'select', name: 'type', label: t('pointsFor'), options: state.dictionary.pointsHistoryTypes },
        {
            type: 'fromtogroup',
            name: 'createdGroup',
            fromField: { type: 'date', name: 'createdFrom', label: t('addPointsFrom') },
            toField: { type: 'date', name: 'createdTo', label: t('addPointsTo') },

        },
       
    ];
}


export const defaultFilters = {

    ideaNr: '',
    symbol: '',
    type: '',
    createdFrom: null,
    createdTo: null,

}


export default function PoinntsHistoryFilters(props) {

    return (
        <FilterForm
            fields={useFieldsConfiguration()}
            defaultFilters={defaultFilters}

        />
    );
}