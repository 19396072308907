import { Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useTimeConverter } from "../../hooks/TimeConverter";
import { clearInterval, setInterval } from 'worker-timers';


export default function AutoLogout() {

    const defaultTimeout = 60 * 15   

    const { t } = useTranslation('main')
    const [state] = useContext(AuthContext);
    const [count, setCount] = useState(defaultTimeout)
    const location = useLocation();
    const { timeLeft, timeColor } = useTimeConverter()
    let navigate = useNavigate()
    let timeInterval = useRef()

    useEffect(() => {

        if (!timeInterval.current) {
            timeInterval.current = setInterval(() => {
                setCount(prevCount => {
                    if (prevCount > 0) {
                        return prevCount - 1
                    }
                })
            }, 1000);
        }
    }, [])

    useEffect(() => {

        if (count < 1) {
            if (timeInterval.current) {
                clearInterval(timeInterval.current)
            }
            navigate('/logout')
        }

    }, [count])




    useEffect(() => {
        setCount(defaultTimeout)
    }, [location])


    return (
        <>
            <Typography variant='span' sx={{ fontSize: '.7em', fontWeight: 400, opacity: '.5' }}>
                {t('youAreGoingToBeLogout')}:
            </Typography>
            <Typography variant='span' sx={{ pl: 1, fontSize: '.8em', fontWeight: 600, color: timeColor(count) }}>
                {timeLeft(count)}
           
            </Typography>
        </>
    )
}