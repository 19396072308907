import { DataGrid } from "@mui/x-data-grid";
import { useDataGridTranslation } from "./DataGridTranslations";



export default function StaticDataGrid(props) {

    
    return (

        <>

            <DataGrid
                autoHeight
                localeText={useDataGridTranslation()}
                rowsPerPageOptions={[5, 10, 25, 50]}
                disableColumnFilter
                pageSize={50}
                {...props}
            />
        </>
    )
}