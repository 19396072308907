import api from "./config/axiosConfig";
import { buildQuery } from "./utils/QueryBuilder";


export const getList = async (limit, page, sort, filters) => {

    return await api.get('points/history' + buildQuery(limit, page, sort, filters))

}

export const getRank = async (limit, page, sort, filters) => {
    return await api.get('points/history-rank' + buildQuery(limit, page, sort, filters))

}

