import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState } from "react";


export default function DeleteConfirmButton(props) {

    const { t } = useTranslation('main')
    const [open, setOpen] = useState(false)


    const handleConfirm = () => {
        setOpen(false)
        props.onDelete && props.onDelete()
    }



    return (
        <>
            <Tooltip title={t('delete')} >
                <IconButton onClick={() => { setOpen(true) }} color="primary" >
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('sureToDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="neutral" onClick={() => setOpen(false)}>{t('cancel')}</Button>
                    <Button variant="contained" color="error" onClick={handleConfirm} autoFocus>{t('delete')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}