import { useTranslation } from "react-i18next";

export const useUserStatuses = () => {

    const { t } = useTranslation('main');

    const statuses = [
        { id: 0, name: t('banned') },
        { id: 1, name: t('active') }
    ];

    return {statuses};
}