import { useTranslation } from "react-i18next";
import ReportPageLayout from "./components/ReportPageLayout";
import { useFieldsConfiguration } from "./components/ReportFilters";
import ReportByStatus from "./components/ReportByStatus";

export default function ReportByStatusPage() {

    const { t } = useTranslation('main')  
    return (
        <ReportPageLayout
            pageTitle={t('reports.status')}
            fieldsConfiguration={useFieldsConfiguration()}
        >
            <ReportByStatus />
        </ReportPageLayout>
    );
}

