import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";


export default function DateFieldElement(props) {
    

    return (
        <Controller
        control={props.control}
        name={props.field.name}
        render={({ field: { ref, onBlur, name, ...field }, fieldState }) =>  (
            
            <DatePicker
                {...field}
                inputRef={ref}
                label={props.field.label}
                inputFormat="DD/MM/YYYY"
                maxDate={props.field.maxDate}
                renderInput={(inputProps) => (
                    
                    <TextField
                        fullWidth
                        {...inputProps}
                        size="small"
                        onBlur={onBlur}
                        name={name}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                    />
                )}
            />
        )}
    />

    )
}