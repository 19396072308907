import { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormTemplate from "./../../components/shared/form/FormTemplate";
import { useResponseHandler } from "./../../hooks/ResponseHandler";
import { getMyPoints } from '../../apis/PointsApi';
import SidebarContent from './../../components/shared/SidebarContent';
import { Grid, TextField, Box, Typography, Paper, Alert } from "@mui/material";
import { currency } from './../../components/shared/Utils';
import { getPointsValue } from '../../apis/PointsApi';
import { newPayout } from '../../apis/PayoutApi';
import { useNavigate } from 'react-router-dom';

export default function NewPayoutRequestPage() {


    const { t } = useTranslation('main');

    const [handleError, handleSuccess] = useResponseHandler()
    const { register, reset, setError, handleSubmit, formState: { errors }, clearErrors } = useForm({ defaultValues: { points: 0 } });

    const [btnDisabled, setBtnDisabled] = useState(false)
    const [btnIsLoading, setBtnIsLoading] = useState(false)
    const [timeoutId, setTimeoutId] = useState(null)
    const [pointsValue, setPointsValue] = useState(0)
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [responseCode, setResponseCode] = useState(0)

    let navigate = useNavigate();



    const onSubmit = (formData) => {
        setBtnIsLoading(true)


        const save = async (params) => {
            await newPayout(params).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response, { 201: t('requestHasBeenSent') });
                    return navigate('/app/payout-request');

                }

                setBtnIsLoading(false)
            })
        }

        save(formData)
    }

    let upController = useRef(null);

    const updatePointsValue = (points) => {

        if (upController.current) {
            upController.current.abort();
        }

        upController.current = new AbortController();

        const updatePoints = async () => {
            await getPointsValue({ points: points }, upController.current).catch((e) => {

            }).then((response) => {
                setPointsValue(response.data?.pointsValue)
            })
        }

        updatePoints();


    }


    const onPointsChange = (input) => {

        clearTimeout(timeoutId);


        let value = input.target.value;

        if (!isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))) {
            clearErrors('points');
            setBtnDisabled(false)
            if (value < 1) {
                value = 1;
                reset({ points: value });
            }
            if (value > data.userPoints.available) {
                value = data.userPoints.available;
                reset({ points: value });
            }

            setTimeoutId(setTimeout(() => { updatePointsValue(value) }, 250))

        } else {
            setBtnDisabled(true)
            setError('points', { type: 'custom', message: t('err.invalidValue') });
        }
    }





    useEffect(() => {

        const controller = new AbortController();

        const getPoints = async () => {
            await getMyPoints(controller).catch((e) => {
                setResponseCode(e?.response?.status)
            }).then((response) => {
                if (response) {
                    setPointsValue(response.data.userPoints.availablePointsValue)
                    setData(response.data)
                    reset({ points: response.data?.userPoints?.available })
                    setResponseCode(response.status)
                }

                setIsLoading(false)
            })
        }

        getPoints()

        return () => controller.abort();



    }, [])

    if (data?.userPoints?.available === 0) {
        return (
            <SidebarContent isLoading={isLoading} responseCode={responseCode}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ pb: 2 }} >
                        <Typography variant="h1" >{t('getPoints')}</Typography>
                    </Grid>
                </Grid>


                <Grid container spacing={3} direction="row" alignItems="stretch">
                    <Grid item xs={12}  >
                        <Paper elevation={2} sx={{ height: '100%' }}  >
                            <Alert severity="info">{t('noPointsToGet')}</Alert>
                        </Paper>
                    </Grid>



                </Grid>
            </SidebarContent>
        )
    }

    return (
        <SidebarContent isLoading={isLoading} responseCode={responseCode}>
            <Grid container>


                <Grid item xs={12} md={8} lg={6}>
                    <FormTemplate
                        formTitle={t('getPoints')}
                        responseCode={responseCode}
                        handleSubmit={handleSubmit(onSubmit)}
                        isLoading={btnIsLoading}
                        btnOptions={{ disabled: btnDisabled }}
                        btnTitle={t('sendRequest')}
                    >

                        <Grid container sx={{ px: 3, pt: 0, pb: 3 }} spacing={5} justifyContent="space-between">
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>

                                <Typography variant="h6" sx={{ pb: 3, display: 'inline-block' }}>{t('yourTotalPoints')}:</Typography>
                                <Typography variant="h5" sx={{ pl: 1, pb: 3, display: 'inline-block' }}>{data?.userPoints?.available} {t('pts')}</Typography>

                            </Grid>

                            <Grid item xs={12} >



                                <Typography variant="h6" sx={{ textAlign: 'center', pb: 3 }}>{t('howManyPoints')}</Typography>

                                <Box display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <TextField
                                        {...register('points')}
                                        autoComplete="off"
                                        type="number"
                                        sx={{ width: '200px' }}
                                        onChange={onPointsChange}
                                        InputProps={{
                                            inputProps: {
                                                min: 1,
                                                max: data?.userPoints?.available,
                                                style: { textAlign: 'center' }
                                            }
                                        }}
                                        error={!!errors.points}
                                        helperText={errors.points?.message} />
                                </Box>

                                <Typography variant="h6" sx={{ textAlign: 'center', mt: 5, pb: 1 }}>{t('payOutInfo')}</Typography>

                                <Typography variant="h5" sx={{ textAlign: 'center' }}>{currency(pointsValue)}</Typography>

                            </Grid>



                        </Grid>

                    </FormTemplate>
                </Grid>
            </Grid>
        </SidebarContent>
    )
}