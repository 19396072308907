import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../components/shared/AuthContext';
import FilterForm from "../../../components/shared/form/FilterForm";


export const useFieldsConfiguration = () => {

    const { t } = useTranslation('main')
    const [state] = useContext(AuthContext)


    return [

        { type: 'multiselect', name: 'type', label: t('pointsFor'), options: state.dictionary.pointsHistoryTypes },
        {
            type: 'fromtogroup',
            name: 'createdGroup',
            fromField: { type: 'date', name: 'createdFrom', label: t('addPointsFrom') },
            toField: { type: 'date', name: 'createdTo', label: t('addPointsTo') },

        },
       
    ];
}


export const defaultFilters = {


    type: [],
    createdFrom: null,
    createdTo: null,

}


export default function PoinntsRankFilters(props) {

    return (
        <FilterForm
            fields={useFieldsConfiguration()}
            defaultFilters={defaultFilters}

        />
    );
}