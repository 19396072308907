import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function Error() {

  const { t } = useTranslation('main')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ p: 5 }}>
            <img src="/img/robot.png" className="fluid" alt="" />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ py: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h1">{t('err.errorOccured')}</Typography>
              <Button href="/app/dashboard" sx={{ my: 5 }} variant="contained">{t('goBackToHome')}</Button>

           
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Error;
