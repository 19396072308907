import { useTranslation } from "react-i18next"
import ManageDialog from "./ManageDialog"
import { update } from "../../../../apis/IdeasGroupsApi"


export default function EditForm(props) {

    const { t } = useTranslation('main')

    return (
        <>
            {props.editElement &&
                <ManageDialog
                    open={true}
                    editElement={props.editElement}
                    close={props.onEditClose}
                    dialogTitle={t('editGroup')}
                    defaultValues={{ name: props.editElement.name, points: props.editElement.points }}
                    save={(formData) => update(props.editElement.id, formData)}
                    updateData={props.updateData}
                    data={props.data}
                />}
        </>
    )
}