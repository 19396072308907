import { TextField } from "@mui/material";

export default function TextFieldElement(props) {



    return (<TextField
        {...props.register(props.field.name)}
        fullWidth
        size="small"
        autoComplete="off"
        label={props.field.label}
    />)
}