import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteGroup, getList } from "../../apis/IdeasGroupsApi";
import StaticDataGrid from "../../components/shared/datagrid/StaticDataGrid";
import SidebarContent from "../../components/shared/SidebarContent";
import SidebarContentTitle from "../../components/shared/SidebarContentTitle";
import { useResponseHandler } from "../../hooks/ResponseHandler";
import AddForm from "./components/ideas-groups/AddForm";
import useColumnsDefinition from "./components/ideas-groups/config/columns";
import EditForm from "./components/ideas-groups/EditForm";


export default function IdeasGroupsSettingsPage() {

    const { t } = useTranslation('main')
    const [isLoading, setIsLoading] = useState(true)
    const [responseCode, setResponseCode] = useState()
    const [data, setData] = useState([])
    const [handleError, handleSuccess] = useResponseHandler()
    const [editElement, setEditElement] = useState(null)

    const updateData = (obj) => {
        const newData = data.filter((o) => o.id !== obj.id);
        newData.push(obj)
        setData(newData)
    }



    const handleDelete = (params) => {

        const deleteIdeaGroup = async () => {
            await deleteGroup(params.id).catch((e) => {
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response)
                    setData(data.filter((obj) => obj.id !== params.id))
                }
            })
        }

        deleteIdeaGroup()

    }


    useEffect(() => {

        const controller = new AbortController();

        const getCompanyGroups = async () => {
            await getList(controller).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                response && setData(response.data.ideaGroups)
                setIsLoading(false)
            })
        }

        getCompanyGroups()

        return () => controller.abort()

    }, [])

    return (
        <SidebarContent isLoading={isLoading} responseCode={responseCode}  noMargins={true} >
            <SidebarContentTitle title={t('settings.ideas')} />
            <Grid container>
                <Grid item xs={12} lg={8}>
                    <Paper sx={{ m: 3 }}>
                        <AddForm updateData={updateData} data={data} />
                        <EditForm updateData={updateData} data={data} editElement={editElement} onEditClose={() => setEditElement(null)} />
                        <StaticDataGrid
                            columns={useColumnsDefinition({
                                onDelete: handleDelete,
                                onEdit: setEditElement
                            })}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,

                                    },
                                },
                            }}
                            rows={data}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </SidebarContent>
    )
}