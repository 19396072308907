import { useTranslation } from "react-i18next";
import { useFieldsConfiguration } from "./components/ReportFilters";
import ReportPageLayout from "./components/ReportPageLayout";
import ReportByTimeChart from "./components/ReportByTimeChart";


export default function ReportByTimePage() {

    const { t } = useTranslation('main')  
    

    return (
        <ReportPageLayout
            pageTitle={t('reports.time')}
            fieldsConfiguration={useFieldsConfiguration()}
        >
            <ReportByTimeChart />
        </ReportPageLayout>
    );
}