import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material";


export const shadows = (mode) => ({
    ...(mode === 'light' ? {
        small: '5px 3px 11px 0px rgba(213, 221, 229, 0.74)',

    } : {
        small: '5px 3px 11px 0px rgba(213, 221, 229, 0.74)',

    })
})


//color design tokens
export const tokens = (mode) => ({
    ...(mode === 'light' ?
        {
            grey: {
                100: "#ffffff",
                200: "#f5f7f9",
                300: "#f1f3f6",
                400: "#eceff3",
                500: "#e7ebf0",
                600: "#b9bcc0",
                700: "#8b8d90",
                800: "#5c5e60",
                900: "#2e2f30"
            },
            primary: {
                100: "#d1e4f6",
                200: "#a3c8ed",
                300: "#75ade4",
                400: "#4791db",
                500: "#1976d2",
                600: "#145ea8",
                700: "#0f477e",
                800: "#0a2f54",
                900: "#05182a"
            },
            neutral: {
                100: "#e9ebed",
                200: "#d3d7db",
                300: "#bec3ca",
                400: "#a8afb8",
                500: "#929ba6",
                600: "#757c85",
                700: "#585d64",
                800: "#3a3e42",
                900: "#1d1f21"
            },
            secondary: {
                100: "#cdcfd2",
                200: "#9a9fa5",
                300: "#686f79",
                400: "#353f4c",
                500: "#030f1f",
                600: "#020c19",
                700: "#020913",
                800: "#01060c",
                900: "#010306"
            }
        } : {
            grey: {
                100: "#2e2f30",
                200: "#5c5e60",
                300: "#8b8d90",
                400: "#b9bcc0",
                500: "#e7ebf0",
                600: "#eceff3",
                700: "#f1f3f6",
                800: "#f5f7f9",
                900: "#fafbfc"
            },
            primary: {
                100: "#d1e4f6",
                200: "#a3c8ed",
                300: "#75ade4",
                400: "#4791db",
                500: "#1976d2",
                600: "#145ea8",
                700: "#0f477e",
                800: "#0a2f54",
                900: "#05182a"
            },
            neutral: {
                100: "#1d1f21",
                200: "#3a3e42",
                300: "#585d64",
                400: "#757c85",
                500: "#929ba6",
                600: "#a8afb8",
                700: "#bec3ca",
                800: "#d3d7db",
                900: "#e9ebed"
            },
            secondary: {
                100: "#010306",
                200: "#01060c",
                300: "#020913",
                400: "#020c19",
                500: "#030f1f",
                600: "#353f4c",
                700: "#686f79",
                800: "#9a9fa5",
                900: "#cdcfd2",
            },
        }
    )
});


export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        shape: {
            borderRadius: 6
        },
        shadows: [
            'none',
            '5px 3px 11px 0px rgba(30, 35, 40, 0.40)',
            '0px 0px 10px 0px rgba(213, 221, 229, 0.74)',
            '5px 3px 11px 0px rgba(213, 221, 229, 0.74)',
            '5px 3px 11px 0px rgba(213, 221, 229, 0.74)',
            '5px 3px 11px 0px green',
            '10px 6px 11px 0px rgba(213, 221, 229, 1)',
            '5px 3px 11px 0px blue',
            '3px 3px 11px 0px rgba(30, 35, 40, 0.40)', //8
            '5px 3px 11px 0px purple',
            '5px 3px 11px 0px rgba(213, 221, 229, 0.74)', //10
            '5px 3px 11px 0px green',
            '5px 3px 11px 0px green',
            '5px 3px 11px 0px green',
            '5px 3px 11px 0px green',
            '5px 3px 11px 0px orange',
            '5px 3px 11px 0px orange',
            '5px 3px 11px 0px orange',
            '5px 3px 11px 0px orange',
            '5px 3px 11px 0px orange',
            '5px 3px 11px 0px blue',
            '5px 3px 11px 0px blue',
            '5px 3px 11px 0px blue',
            '5px 3px 11px 0px blue',
            '0px 0px 10px 0px rgba(30, 35, 40, 0.74)', //24
           
        ],
        palette: {
            mode: mode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: colors.primary[500]
                    },
                    secondary: {
                        main: colors.secondary[500]
                    },
                    neutral: {
                        main: colors.neutral[500]
                    },
                    background: {
                        default: colors.grey[500]
                    }
                } : {
                    primary: {
                        main: colors.primary[500]
                    },
                    secondary: {
                        main: colors.secondary[500]
                    },
                    neutral: {
                        main: colors.neutral[500]
                    },
                    background: {
                        default: '#000000'
                    }
                }
            )
        },
        typography: {
            fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
            fontSize: 14,
            h1: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '2rem',
                fontWeight: 300
            },
            h2: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '3.5rem',
            },
            h3: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '1.6rem',
                fontWeight: 400
            },
            h4: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '2.5rem',
                fontWeight: 700
            },
            h5: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '1.4rem',
                fontWeight: 700
            },
            h6: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '1.1rem',
                fontWeight: 400
            },
            light: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '0.8rem',
                fontWeight: 400,
                color: colors.grey[600]
            },
            default: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '1rem',
                fontWeight: 400,
     
            },
            defaultText: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '0.9rem',
                fontWeight: 400,
     
            },
            defaultLabel: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '0.8rem',
                fontWeight: 400,
                color: colors.grey[700]
            },
            medium: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontWeight: 600
                
            }
        
        },

    }
}


export const ColorModeContext = createContext({
    toogleColorMode: () => { }
});

export const useMode = () => {
    const [mode, setMode] = useState('light')

    const colorMode = useMemo(() => ({
        toogleColorMode: () => setMode((prev) => (prev === 'dark' ? 'light' : 'dark'))
    }), []);

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode];
}