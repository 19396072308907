import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { buildQuery, useQueryParams } from "../../../apis/utils/QueryBuilder";
import { DataGridContext } from "./DataGridContext";



function DataGridContextProvider(props) {

    let queryParams = useQueryParams();
    let location = useLocation();
    let navigate = useNavigate();

    const defaultLimit = (props.limit) ? parseInt(props.limit) : 10;

    const limit = queryParams.limit ? queryParams.limit : ((props.limit) ? parseInt(props.limit) : 10)
    const page = queryParams.page ? queryParams.page : 1
    const sort = queryParams.sort ?? []
    const urlFilters = Object.keys(queryParams.filters).length > 0 ? queryParams.filters : null;


    const [dataGridState, setDataGridState] = useState({
        isLoading: true,
        rows: [],
        totalRows: 0,
        page: page,
        limit: limit,
        defaultLimit: limit,
        sort: sort,
        filters: urlFilters,
        reload: false
    });

    const [preventRedirect, setPreventRedirect] = useState(true);

    const setDataGridContext = (newState) => {

        setDataGridState({ ...dataGridState, ...newState });
    };


    useEffect(() => {

        setDataGridContext({
            reload: true,
        });

        //to avoid add limit param to url when limit is equal default limit
        const queryLimit = dataGridState.limit === defaultLimit ? null : dataGridState.limit

        let newUrl = location.pathname + buildQuery(queryLimit, dataGridState.page, dataGridState.sort, dataGridState.filters);
        let current = window.location.href.replace(window.location.origin, '')

        if (newUrl !== current)
            navigate(location.pathname + buildQuery(queryLimit, dataGridState.page, dataGridState.sort, dataGridState.filters))


    }, [dataGridState.limit, dataGridState.page, dataGridState.sort, dataGridState.filters])



    useEffect(() => {

        const limit = queryParams.limit ? queryParams.limit : ((props.limit) ? parseInt(props.limit) : 10)
        const page = queryParams.page ? queryParams.page : 1
        const sort = queryParams.sort ?? []
        const urlFilters = Object.keys(queryParams.filters).length > 0 ? queryParams.filters : null;



        setDataGridContext({
            page: page,
            limit: limit,
            sort: sort,
            filters: urlFilters,
            reload: true,
        });


    }, [])






    return (
        <DataGridContext.Provider value={[dataGridState, setDataGridContext]}>
            {props.children}
        </DataGridContext.Provider>
    );
}

export default DataGridContextProvider;
