import api from "./config/axiosConfig";
import { buildQuery } from "./utils/QueryBuilder";


export const getList = async (limit, page, sort, filters, signal) => {

    return await api.get('ideas' + buildQuery(limit, page, sort, filters))

}

export const get = async (id) => {
    return await api.get('ideas/' + id)

}

export const deleteIdea = async (id) => {
    return await api.delete('ideas/' + id)
}

export const create = async (data) => {
    return await api.post('ideas', data)

}

export const update = async (id, data) => {
    return await api.put('ideas/' + id, data)

}

export const download = async (id) => {
    return api.get('attachment/' + id, {
        responseType: 'blob',
    })
}

export const sendToCache = async (data) => {
    return api.post('cache',data)
}