import React from "react";
import { useState } from "react";
import { SidebarContext } from "./SidebarContext";

function SidebarContextProvider({ children }) {
    const [state, setState] = useState({
        opened: false,
        mobileOpened: false       
    });

    const setContext = (newState) => {
        setState({ ...state, ...newState });
    };

  

    return (
        <SidebarContext.Provider value={[state, setContext]}>
            {children}
        </SidebarContext.Provider>
    );
}

export default SidebarContextProvider;
