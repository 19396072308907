import { getDatesFromFilters, getBiggerLowerDate, getDatesArray, getLabelsByDatesArray, getDateRangeFromData, covertDataToObject } from "./shared"

const STATUSES = {
    CONSULTATION: 'consultation',
    ACCEPTED: 'accepted',
    REFUSED: 'refused',
    DEPLOYED: 'deployed'
}


const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plugins: {
        legend: {
            position: 'top'
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
}

const getDatasets = (dates, data, statuses) => {
    return statuses.map((status) => getDataset(status, dates, data.filter((row) => row.type === status.type)))
}

const getDataset = (status, dates, data) => {


    return {
        label: status.name,
        data: fillDates(dates, data),
        backgroundColor: getDatasetColor(status.type),
        stack: 'x'
    }
}

const getDatasetColor = (status) => {
    switch (status) {
        case STATUSES.CONSULTATION:
            return 'rgba(235, 235, 235, 0.8)'
        case STATUSES.ACCEPTED:
            return 'rgba(46, 125, 50, 0.8)'
        case STATUSES.REFUSED:
            return 'rgba(211, 47, 47, 0.8)'
        case STATUSES.DEPLOYED:
            return 'rgba(2, 136, 209, 0.8)'

    }

    const red = Math.floor(Math.random() * 256)
    const green = Math.floor(Math.random() * 256)
    const blue = Math.floor(Math.random() * 256)

    return `rgba(${red},${green},${blue}, 0.8)`

}

const fillDates = (dates, data) => {

    const dataObj = covertDataToObject(data)

    return dates.map((date) => {
        const prop = date.toISOString().slice(0, 7);
        return (prop in dataObj) ? dataObj[prop] : 0

    })
}


export const getByStatusChartData = (data, filters, statuses) => {

    const { startDate, endDate } = getDatesFromFilters(filters)
    const { dataStartDate, dataEndDate } = getDateRangeFromData(data)

    const start = getBiggerLowerDate(startDate, dataStartDate, false)
    const end = getBiggerLowerDate(endDate, dataEndDate, true)

    if ((start === null || end === null) || (start > end)) {
        return { data: null, options: null }
    }

    const dates = getDatesArray(start, end)
    const labels = getLabelsByDatesArray(dates)


    return {
        data: {
            labels,
            datasets: getDatasets(dates, data, statuses),
        },
        options: options
    }
}