import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthContext } from './shared/AuthContext';


import { Typography } from '@mui/material';
import AutoLogout from './shared/AutoLogout';

export default function AccountMenu() {

  const { t } = useTranslation('main');
  const [state] = useContext(AuthContext);
  const accountLetter = state?.user?.firstName?.trim().charAt(0) || '?';
  const userSymbol = state.user?.symbol ? ' (' + state.user.symbol + ')' : ''

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };





  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant='h6' sx={{ fontSize: '.8em', fontWeight: 400, lineHeight: '.5em', pt: 1 }}>
            {state.user.firstName} {state.user.lastName}{userSymbol}
          </Typography>
          {!state.autoLogout &&
            <><Typography variant='span' sx={{ fontSize: '.7em', fontWeight: 400, opacity: '.5' }}>
              {state.user.role.name}
            </Typography>
            </>
          }
          {state.autoLogout &&
            <AutoLogout/>
          }
        </Box>
        <Tooltip title={t('account')}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{accountLetter}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        className="context-menu"
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/app/my-profile">
            <ListItemIcon>
              <AccountCircleOutlinedIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            {t('myProfile')}
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleClose}>
          <Link to="/app/password">
            <ListItemIcon>
              <Settings sx={{ fontSize: 18 }} />
            </ListItemIcon>
            {t('changePassword')}
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/logout">
            <ListItemIcon>
              <Logout sx={{ fontSize: 18 }} />
            </ListItemIcon>

            {t('logout')}


          </Link>
        </MenuItem>

      </Menu>
    </React.Fragment>
  );
}