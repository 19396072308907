import { useContext } from "react";
import { AuthContext } from "../../../components/shared/AuthContext";
import { useTranslation } from "react-i18next";


export const useUserNotifications = () => {
    
    const [state] = useContext(AuthContext);
    

    let allowedNotifications = [];

    allowedNotifications.push('meAsSupervisor')
    state.privileges.includes('prmNewIdeaEmail') && allowedNotifications.push('newIdea')
    state.privileges.includes('prmNewPointsEmail') && allowedNotifications.push('newPoints')
    state.privileges.includes('prmIdeaStatusChangedEmail') && allowedNotifications.push('statusChanged')
    state.privileges.includes('prmNewPayoutRequestEmail') && allowedNotifications.push('newPayoutRequest')

    return {allowedNotifications: allowedNotifications}


}