import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useColors } from "../Utils";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import FormElement from './FormElement';
import { DataGridContext } from '../datagrid/DataGridContext';
import DatePickerLocalizationProvider from '../DatePickerLocalizationProvider';


export default function FilterForm(props) {

    const colors = useColors()
    const { t } = useTranslation('main')
    const [open, setOpen] = useState(false);
    const [dataGridState, setDataGridContext] = useContext(DataGridContext)



    const handleClickOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false);
        reset()
    }



    const handleReset = () => {
        reset()

    }

    const onSubmit = (data) => {



        let filtersData = null;
        for (const property in data) {
            if (data[property] || data[property] === 0) {
                filtersData = data;
                break;
            }

        }
        

        

        setDataGridContext({ filters: filtersData })
        setOpen(false);

    }

    const { register, handleSubmit, reset, setValue, control, formState: { errors } } = useForm({
        defaultValues: props.defaultFilters
    });

    useEffect(() => {
        reset()
        if (open && dataGridState.filters) {
            for (const property in dataGridState.filters) {
                setValue(property, dataGridState.filters[property])
            }
        }

    }, [open])



    return (

        <>
            <Tooltip title={t('searchFilters')} >
                <IconButton size="small" onClick={handleClickOpen}>
                    <FilterAltOutlinedIcon sx={{ color: colors.grey[900] }} />
                </IconButton>
            </Tooltip>

            <Dialog fullWidth scroll="body" maxWidth="md" open={open} onClose={handleClose}  >
                <form>
                    <DatePickerLocalizationProvider>

                        <DialogTitle>{t('searchFilters')}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3} >
                                {props.fields.map((field) => (
                                    < Grid key={field.name} item xs={12} sm={6} sx={{ mt: 1 }}>
                                        <FormElement field={field} register={register} control={control} />
                                    </Grid>
                                ))
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container>
                                <Grid item xs={6} sx={{ p: 1 }}>
                                    <Button onClick={handleReset} sx={{ color: colors.grey[700] }}>{t('resetFilters')}</Button>
                                </Grid>
                                <Grid item xs={6} sx={{ p: 1, textAlign: 'right' }}>
                                    <Button onClick={handleClose}>{t('cancel')}</Button>
                                    <Button onClick={handleSubmit(onSubmit)}>{t('filter')}</Button>
                                </Grid>
                            </Grid>


                        </DialogActions>

                    </DatePickerLocalizationProvider>
                </form>
            </Dialog>



        </>
    )
}