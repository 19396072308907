import api from "./config/axiosConfig";


export const updatePassword = async (data) => {
    return api.put('profile/password', data)
}


export const getProfile = async (controller) => {
    return api.get('profile', { signal: controller.signal })
}

export const updateProfile = async (data) => {
    return api.put('profile', data)
}