import { FormControl, FormHelperText, InputLabel, MenuItem, Select, ListItemText, OutlinedInput, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function MultiSelectFieldElement(props) {

    const { t } = useTranslation('main')

    const labelId = "label-" + props.field.name;

    const renderValues = (selected) => {


        

        return props.field.options.filter(obj => selected.includes(obj.id))
            .map(obj => obj.name)
            .join(', ');
    }

    return (

        <FormControl sx={{ minWidth: '100%', maxWidth: '300px' }} size="small">
            <InputLabel id={labelId}>{props.field.label}</InputLabel>
            <Controller
                name={props.field.name}
                control={props.control}
                defaultValue={[]} // Default value for the select
                render={({ field: { onChange, value } }) => (
                    <Select
                        labelId={labelId}
                        label={props.field.label}
                        multiple
                        value={value}
                        onChange={onChange}
                        input={<OutlinedInput label={props.field.label} />}
                        renderValue={renderValues}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 224,
                                    width: 250,
                                },
                            },
                        }}

                    >
                        {props.field.options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                <Checkbox checked={value.indexOf(option.id) > -1} />
                                <ListItemText primary={option.name} />
                            </MenuItem>


                        ))}
                    </Select>
                )}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>

        
    )
}