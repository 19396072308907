import api from "./config/axiosConfig";
import { buildQuery } from "./utils/QueryBuilder";


export const getByTime = async (filters, controller) => {
    return await api.get('report/bytime' + buildQuery(null, null, null, filters), { signal: controller.signal })

}


export const getByUser = async (filters, controller) => {
    return await api.get('report/byuser' + buildQuery(null, null, null, filters), { signal: controller.signal })
}

export const getByStatus = async (filters, controller) => {
    return await api.get('report/bystatus' + buildQuery(null, null, null, filters), { signal: controller.signal })
}