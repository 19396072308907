import { Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

export default function YourIdeas(props) {

    const { t } = useTranslation('main')
    const ideas = props.ideas;

    if (ideas) {
        return (
            <Paper elevation={2} sx={{ height: '100%' }}>
                <Grid container sx={{ p: 2 }}>
                    <Grid item xs={9}>
                        <Typography variant="h6" >{props.title ? props.title : t('yourIdeas')}</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <LightbulbOutlinedIcon />
                    </Grid>

                    <Grid container >
                        <Grid item xs={12} sm={12} md={2} sx={{ textAlign: { xs: 'center', sm: 'center' } }}>
                            <Typography variant="light">{t('total')}</Typography>
                            <Typography variant="h4" sx={{ fontSize: { xs: '4em', sm: '2.5em' } }}>{ideas.total}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} sx={{ pt: { xs: 0, sm: 2 } }}>
                            <Grid container >
                                <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }} >
                                    <Typography variant="light">{t('inReview')}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: { xs: '2em', sm: '1.2em' } }}>{ideas.consultation}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }} >
                                    <Typography variant="light">{t('refused')}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: { xs: '2em', sm: '1.2em' } }}>{ideas.refused}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }} >
                                    <Typography variant="light">{t('accepted')}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: { xs: '2em', sm: '1.2em' } }}>{ideas.accepted}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3} sx={{ textAlign: 'center' }} >
                                    <Typography variant="light">{t('deployed')}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: { xs: '2em', sm: '1.2em' } }}>{ideas.deployed}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>



            </Paper>

        )
    }
}