import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useResponseHandler } from "./../../../../../hooks/ResponseHandler";

export default function ManageDialog(props) {

    const { t } = useTranslation('main')
    const [handleError, handleSuccess] = useResponseHandler()

    const schema = yup.object({
        fromPoints: yup.number(t('err.integer'))
            .typeError(t('err.integer'))
            .required(t('err.required'))
            .integer(t('err.integer'))
            .min(1, t('err.greatherThan', { min: 0 }))
            .max(10000, t('err.lessThanOrEqual', { max: 10000 })),
        toPoints: yup.number(t('err.integer'))
            .typeError(t('err.integer'))
            .integer(t('err.integer'))
            .min(props.defaultValues.fromPoints, t('err.greatherThanOrEqual', { min: props.defaultValues.fromPoints }))
            .test("divde", t('err.hasToBeDevide10'), val => (val % 10) === 0)
            .transform((_, val) => (val !== "" ? Number(val) : null)),

        value: yup.string(t('err.integer'))
            .required(t('err.required'))
            .transform((_, val) => (val !== "" ? Number(val).toFixed(2) : ''))


    }).required();

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ defaultValues: props.defaultValues, resolver: yupResolver(schema) });

    const clear = () => reset(props.defaultValues)

    const onClose = () => {
        clear()
        props.close()
    }




    const onSubmit = async (data) => {
        await props.save(data).catch((e) => {
            handleError(e)
        }).then((response) => {

            if (response) {
                handleSuccess(response)
                response && onClose()
                props.updateData(response.data.pointsRange)
            }
        })
    }

    useEffect(() => {
        if (props.open) {
            reset(props.defaultValues)
        }
    }, [props.open])

    return (
        <Dialog fullWidth scroll="body" maxWidth="sm" open={props.open}  >
            <form onSubmit={handleSubmit(onSubmit)}>


                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={5} sx={{ mt: 1 }}>
                            <TextField
                                {...register('fromPoints')}
                                fullWidth
                                disabled
                                type="number"

                                label={t('fromPoints')}
                                error={!!errors.fromPoints}
                                autoComplete="off"
                                helperText={errors.fromPoints?.message}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{t('pts')}</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ mt: 1, pt: 1, textAlign: 'center' }}>
                            -
                        </Grid>
                        <Grid item xs={5} sx={{ mt: 1 }}>
                            <TextField
                                {...register('toPoints')}
                                fullWidth
                                disabled={!(!props.editElement || props.editElement?.isLast)}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={t('toPoints')}
                                error={!!errors.toPoints}
                                autoComplete="off"
                                helperText={errors.toPoints?.message}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{t('pts')}</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12} sx={{ mt: 5 }}>
                            <TextField
                                {...register('value')}
                                fullWidth
                                type="number"
                                label={t('pointsValueForEach10')}
                                error={!!errors.value}
                                autoComplete="off"
                                helperText={errors.value?.message}
                                InputProps={{

                                    endAdornment: <InputAdornment position="end">{t('pln')}</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12} sx={{ px: 1, pb: 1, textAlign: 'right' }}>
                            <Button onClick={onClose} sx={{ mr: 2 }}>{t('cancel')}</Button>
                            <Button variant="contained" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
                        </Grid>
                    </Grid>


                </DialogActions>


            </form>
        </Dialog>
    )


}