import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../components/shared/AuthContext";
import { useIdeaFormValidationSchema } from "./components/config/ValidationSchema";
import ManageIdea from "./components/ManageIdea";
import { create } from "../../apis/IdeasApi";
import { useNavigate } from "react-router-dom";

function AddIdeaPage() {

  const { t } = useTranslation('main')
  const [state] = useContext(AuthContext)
  const navigate = useNavigate()



  const defaultValues = {
    idea: {
      title: '',
      problemDescription: '',
      solution: '',
      companyGroup: (state.user?.companyGroup?.id) ? state.user?.companyGroup?.id + '' : ''
    }
  }
  
  


  return (
    <ManageIdea
      formTitle={t('addNewIdea')}
      schema={useIdeaFormValidationSchema()}
      defaultValues={defaultValues}
      save={create}
      onSuccess={() => navigate('/app/ideas')}


    />

  );
}

export default AddIdeaPage;
