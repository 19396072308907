import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext"



export default function Guard(props) {

    const [state] = useContext(AuthContext)

    const includeAll = (grantedList) => {
        return grantedList.every(i => state.privileges.includes(i));
    }

    if (!props.granted) {
        return (<Navigate to="/app/dashboard" />)
    }

    if (typeof props.granted === 'string' && !state.privileges.includes(props.granted)) {
        return (<Navigate to="/app/dashboard" />)
    }

    if (Array.isArray(props.granted) && !includeAll(props.granted)) {
        return (<Navigate to="/app/dashboard" />)
    }



    return (<>{props.children}</>)





}