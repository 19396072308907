export const getTotal = (data) => {
    let total = 0
    for (const index in data) {
        total += data[index].total
    }
    return total
}

export const getByEmployeesData = (data) => {

    const labels = data.map((item) => `${item.firstName} ${item.lastName}`)


    return {
        data: {
            labels,
            datasets: [
                {
                    label: 'Liczba pomysłów',
                    data: data.map((item) => item.total),
                    backgroundColor: 'rgba(20, 140, 250, 0.7)',
                },

            ],
        },
        options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top'
                },
            }
        }
    }
}