import { getDatesFromFilters, getBiggerLowerDate, getDatesArray, getLabelsByDatesArray, getDateRangeFromData, covertDataToObject } from "./shared"



export const getByTimeChartData = (data, filters) => {

    const { startDate, endDate } = getDatesFromFilters(filters)
    const { dataStartDate, dataEndDate } = getDateRangeFromData(data)

    const start = getBiggerLowerDate(startDate, dataStartDate, false)
    const end = getBiggerLowerDate(endDate, dataEndDate, true)

    if ((start === null || end === null) || (start > end)) {
        return { data: null, options: null }
    }

    const dates = getDatesArray(start, end)
    const labels = getLabelsByDatesArray(dates)
    const dataObj = covertDataToObject(data)
    
   

    const filledDates = dates.map((date) => {
        const prop = date.toISOString().slice(0, 7);
        return (prop in dataObj) ? dataObj[prop] : 0

    })



    return {
        data: {
            labels,
            datasets: [
                {
                    label: 'Liczba pomysłów',
                    data: filledDates,
                    backgroundColor: 'rgba(20, 140, 250, 0.7)',
                },

            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top'
                },
            }
        }
    }
}