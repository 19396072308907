import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next"

export default function UserStatusLabel(props) {

    const { t } = useTranslation('main')

    let color = 'default';
    let name = '-';
    switch (props.status) {
        case 0:
            color = 'error';
            name = 'banned';
            break;
        case 1:
            color = 'success';
            name = 'active';
            break;

    }

    return (<Chip size="small" label={t(name)} color={color} />)

}