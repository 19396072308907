import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useResponseHandler } from "./../../../hooks/ResponseHandler";


export default function StartSaldoDialog(props) {

    const { t } = useTranslation('main')
    const [handleError, handleSuccess] = useResponseHandler()
   
    const schema = yup.object({
        saldo: yup.number(t('err.integer'))
            .typeError(t('err.integer'))
            .required(t('err.required'))
            .integer(t('err.integer'))
            .min(0, t('err.greatherThanOrEqual', { min: 0 }))
            .max(10000, t('err.lessThanOrEqual', { max: 1000000 }))

    }).required();

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ defaultValues: props.defaultValues, resolver: yupResolver(schema) });

    const clear = () => reset( props.defaultValues)

    const onClose = () => {
        clear()
        props.close()
    }

   
    const onSubmit = async (data) => {
        await props.save(data).catch((e) => {
            handleError(e)
        }).then((response) => {

            if (response) {
                handleSuccess(response)
                response && onClose()
                reset(data)
                props.updateData(response.data.saldo)
            }
        })
    }


    return (
        <Dialog fullWidth scroll="body" maxWidth="xs" open={props.open}  >
            <form onSubmit={handleSubmit(onSubmit)}>


                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <Grid container >
                       
                        <Grid item xs={12} sx={{ mt: 5 }}>
                            <TextField
                                {...register('saldo')}
                                fullWidth

                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={t('startSaldo')}
                                error={!!errors.saldo}
                                autoComplete="off"
                                helperText={errors.saldo?.message}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{t('pts')}</InputAdornment>,
                                  }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12} sx={{ px: 1, pb: 1, textAlign: 'right' }}>
                            <Button onClick={onClose} sx={{ mr: 2 }}>{t('cancel')}</Button>
                            <Button variant="contained" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
                        </Grid>
                    </Grid>


                </DialogActions>


            </form>
        </Dialog>
    )


}