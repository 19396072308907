import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../components/shared/AuthContext";
import { useUserFormValidationSchema } from "./components/config/ValidationSchema";
import ManageUser from "./components/ManageUser";
import { create } from "../../apis/UsersApi";
import { useNavigate } from "react-router-dom";

function AddUserPage() {

  const { t } = useTranslation('main')
  const navigate = useNavigate()

  const defaultValues = {
    username: '',
    role: '',
    companyGroup: '',
    status: '1',
    password: '',
    confirmPassword: '',
  }

  const onSuccess = () => {
      navigate('/app/users')
  }
    
  return (
    <ManageUser
      formTitle={t('addNewUser')}
      schema={useUserFormValidationSchema()}
      defaultValues={defaultValues}
      save={create}
      onSuccess={onSuccess}      

    />

  );
}

export default AddUserPage;
