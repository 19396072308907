import api from "./config/axiosConfig";



export const userStats = async () => {
    return await api.get('profile/stats');
}

export const getUserStats = async (id, controller) => {
    return await api.get(`profile/stats/${id}`, { signal: controller.signal });
}


