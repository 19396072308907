import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Checkbox, Container, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { login } from "../apis/AuthApi";
import { AuthContext } from '../components/shared/AuthContext';






function LoginPage() {

  const [state, setContext] = useContext(AuthContext);

  const { t } = useTranslation('main');

  const schema = yup.object({
    username: yup.string().required(t('err.required')),
    password: yup.string().required(t('err.required')),
  }).required();


  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });


  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')



  const handleError = (error) => {

    if (error?.response?.status === 401) {
      setErrorMsg(t('credentialsInvalid'))
      return;
    }
    setErrorMsg(t('error'));
  }


  let navigate = useNavigate()
  const onSubmit = async (data) => {

    setErrorMsg('')
    setIsLoading(true)

    const result = await login(data.username, data.password)
      .catch(handleError);

    setIsLoading(false)

    if (result) {

      localStorage.setItem("isAuth", true);
      !data.rememberMe && localStorage.setItem("autoLogout", true);
      !data.rememberMe && sessionStorage.setItem("autoLogout", true);
      setContext({ isAuth: true, autoLogout: !data.rememberMe });
      return navigate('/app/dashboard')
    }

  }

  useEffect(() => {
    if (state.isAuth) {
      navigate('/app/dashboard')
    }
  }, [])

  return (
    <>
      <Container maxWidth="sm" sx={{ alignItems: 'center', mt: 10 }} >
        <Grid container sx={{ justifyContent: 'center' }} >
          <Grid item xs={12} sm={10} sx={{ justifyContent: 'center' }} >
            <Paper sx={{ px: 4, py: 1 }} elevation={3}>

              <Grid container>
                <Grid item xs={12}>

                  <Typography variant="h1" gutterBottom align="center" sx={{ mt: 3 }}>
                    {t('signIn')}
                  </Typography>
                </Grid>
              </Grid>

              {errorMsg &&
                <Grid container>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Alert severity="error">{errorMsg}</Alert>
                  </Grid>
                </Grid>
              }

              <form onSubmit={handleSubmit(onSubmit)} >

                <Grid container>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <TextField
                      {...register('username')}
                      fullWidth
                      autoComplete="off"
                      label={t('username')}
                      error={!!errors.username}
                      helperText={errors.username?.message} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <TextField
                      {...register('password')}
                      fullWidth
                      label={t('password')}
                      type="password"
                      error={!!errors.password}
                      helperText={errors.password?.message} />
                  </Grid>
                </Grid>

                <FormGroup>
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    {...register('rememberMe')}
                    control={<Checkbox />}
                    label={t('rememberAtThisDevice')} />
                </FormGroup>

                <Grid container>
                  <Grid item xs={12} sx={{ my: 5 }}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      loading={isLoading}
                    >
                      {t('logIn')}
                    </LoadingButton>
                  </Grid>
                </Grid>

              </form>

            </Paper>
          </Grid>

          <Grid item xs={12} sm={10} sx={{ justifyContent: 'center', textAlign: 'center', py: 4, opacity: '0.3' }} >
            <img src="/img/kaizen_logo.svg" className="kaizen-dark"  alt=""/>
            </Grid>

        </Grid>
      </Container>




    </>
  );
}

export default LoginPage;
