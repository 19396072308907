import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateStatus } from '../../../apis/PayoutApi';
import { useRequestStatus } from '../hooks/NewRequestStatus';
import { DataGridContext } from './../../../components/shared/datagrid/DataGridContext';
import { useIsAllowed } from "./../../../components/shared/IsAllowed";
import { useResponseHandler } from "./../../../hooks/ResponseHandler";

export default function PayoutStatusLabel(props) {

    const { t } = useTranslation('main')
    const isAllowed = useIsAllowed()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [handleError, handleSuccess] = useResponseHandler()
    const [dataGridState, setDataGridContext] = useContext(DataGridContext)

    const { getNewStatusId } = useRequestStatus()


    const handleChange = () => {
        setOpen(true)

    }

    const handleConfirm = () => {
        setOpen(false)
        setIsLoading(true)
        const newStatusId = getNewStatusId(props.request.status)

        const update = async (payoutRequest) => {
            await updateStatus(payoutRequest.id, { status: newStatusId }).catch((e) => {
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response);
                    setDataGridContext({ reload: true })

                }

                setIsLoading(false)
            })
        }

        update(props.request)

    }

    let color = 'default';
    switch (props.request.status.type) {
        case 'done':
            color = 'success';
            break;
        case 'inProgress':
            color = 'default';
            break;
    }



    if (!isAllowed('rolePayoutRequestUpdate'))
        return (<Chip size="small" label={props.request.status.name} color={color} />)

    if (isLoading)
        return (<CircularProgress color="primary" size={20} />)


    return (
        <>
            <Tooltip title={t('changeStatus')} >
                <Chip
                    size="small"
                    label={props.request.status.name}
                    color={color}
                    onClick={handleChange}
                    onDelete={handleChange}
                    deleteIcon={<EditIcon />}
                />
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('sureToChangeStatus')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="neutral" onClick={() => setOpen(false)}>{t('no')}</Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm} autoFocus>{t('yes')}</Button>
                </DialogActions>
            </Dialog>
        </>

    )




}