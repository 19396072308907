import  api  from "./config/axiosConfig";



export const dictionary = async () => {

        const response = await api.get('dictionary')

        return response;
    
}


export const usersList = async() => {

        const response = await api.get('users-select-list')
        return response;

}