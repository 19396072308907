import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { sendToCache } from "../../../apis/IdeasApi";
import { useResponseHandler } from "../../../hooks/ResponseHandler";
import DrawAttachment from './DrawAttachment';

export default function Attachments(props) {

    const { t } = useTranslation('main')

    const [isLoading, setIsLoading] = useState(false)
    const [handleError] = useResponseHandler()

    const sendAttachmentToCache = async (file) => {
           
        const files = new FormData();
        files.append('file', file, file.name)
        await sendToCache(files).catch((e) => {
            handleError(e)
        }).then((rsp) => {
            if (rsp) {
                props.attachments.push(rsp.data)
                props.onUpdate(props.attachments)
            }
            setIsLoading(false)
        })
    }


    const onAddFiles = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        setIsLoading(true)
        sendAttachmentToCache(e.target.files[0])
    }

    const onDrawSave = (file)=>{
        setIsLoading(true)
        sendAttachmentToCache(file)
    }

    const onDelete = (id) => {
        const newList = props.attachments.filter((value, index) => {
            return index !== id;
        });

        props.onUpdate(newList)
    }

    

    return (
        <>
            <Box>
                {props.attachments.map(function (d, idx) {
                    return (
                        <Box key={idx}>
                            <IconButton onClick={() => onDelete(idx)} sx={{ mr: 1 }}>
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                            {d.oryginalFileName}
                        </Box>

                    )
                })}
            </Box>

            <LoadingButton sx={{ mt: 1, mr: 2 }} loading={isLoading} variant="outlined" component="label">
                <AttachmentOutlinedIcon sx={{ mr: 1 }} />
                {t('addAttachment')}
                <input hidden accept="image/*" onChange={onAddFiles} type="file" />
            </LoadingButton>
            <DrawAttachment onSave={onDrawSave} />
            
        </>)
}