import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { exists, symbolExists } from "../../../../apis/UsersApi";
import { useRef } from "react";




export const useUserFormValidationSchema = (mode, editObject) => {

    if (!mode)
        mode = 'add'

    const { t } = useTranslation('main')

    let existsController = useRef(null);

    let symbolExistsController = useRef(null);


    const username = yup.string()
        .required(t('err.required'))
        .min(4, t('err.min', { min: 4 }))
        .max(99, t('err.max', { max: 99 }))
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9._@-]*[a-zA-Z0-9]$/, t('err.usernameCharacters'))
        .test('Unique Username', t('err.usernameExists'),
            function (value) {

                if (editObject && editObject.username === value) {
                    return true
                }

                if (existsController.current) {
                    existsController.current.abort();
                }

                existsController.current = new AbortController();

                return new Promise((resolve, reject) => {

                    exists(value, existsController.current).then((res) => {
                        resolve(!res.data.exists)
                    })
                        .catch((error) => {
                            resolve(false)
                        })
                })

            }
        )
        ;

    const password = yup.string()
        .required(t('err.required'))
        .min(6, t('err.min', { min: 6 }))
        .max(99, t('err.max', { max: 99 }))
        .matches(/^[\S]*$/, t('err.password'));
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z0-9!@#\$%\^&\*])(?=.{6,})/, t('err.password'));

    const confirmPassword = yup.string()
        .required(t('err.required'))
        .oneOf([yup.ref("password"), null], t('err.notTheSame'));

    const status = yup.string()
        .required(t('err.required'));

    const role = yup.string()
        .required(t('err.required'));

    const symbol = yup.string()
        .required(t('err.required'))
        .min(2, t('err.min', { min: 2 }))
        .max(50, t('err.max', { max: 50 }))
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9]*[a-zA-Z0-9]$/, t('err.symbolCharacters'))
        .test('Unique symbol', t('err.symbolExists'),
            function (value) {

                if (editObject && editObject.symbol === value) {
                    return true
                }

                if (symbolExistsController.current) {
                    symbolExistsController.current.abort();
                }

                symbolExistsController.current = new AbortController();

                return new Promise((resolve, reject) => {

                    symbolExists(value, symbolExistsController.current).then((res) => {
                        resolve(!res.data.exists)
                    })
                        .catch((error) => {
                            resolve(false)
                        })
                })

            }
        );

    const firstName = yup.string()
        .required(t('err.required'))
        .min(2, t('err.min', { min: 2 }))
        .max(50, t('err.max', { max: 50 }))
        .transform((_, val) => val.trim());

    const lastName = yup.string()
        .required(t('err.required'))
        .min(2, t('err.min', { min: 2 }))
        .max(60, t('err.max', { max: 60 }))
        .transform((_, val) => val.trim());



    const email = yup.string()
        .email(t('err.emailInvalid'));





    let defaultSchema = {
        username: username,
        status: status,
        role: role,
        symbol: symbol,
        firstName: firstName,
        lastName: lastName,
        email: email

    }

    let passwordSchema = {
        password: password,
        confirmPassword: confirmPassword
    }


    let myprofile = {
        user: yup.object({
            firstName: firstName,
            lastName: lastName,
            email: email
        })
    }

    switch (mode) {
        case 'edit':
            return yup.object(defaultSchema)
        case 'password':
            return yup.object(passwordSchema)
        case 'myprofile':
            return yup.object(myprofile)
        default:
            return yup.object({ ...defaultSchema, ...passwordSchema })

    }










}