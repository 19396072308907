import { useLocation } from "react-router-dom";


const getValue = (value) => {

    if (typeof value !== 'object')
        return value;


    if (value.constructor.name === 'M' || value.constructor.name === 'm') {
        return value.format('YYYY-MM-DD')
    }



    return value;

}


export const buildQuery = (limit, page, sort, filters) => {

    const searchParams = new URLSearchParams();



    if (limit && limit) searchParams.append("limit", limit);

    if (page && page != 1) searchParams.append("page", page);

    if (sort && sort.length) {
        searchParams.append("by", sort[0].field);
        searchParams.append("sort", sort[0].sort);
    }

    if (filters) {
        for (const filterKey in filters) {
            if (!filters[filterKey] && filters[filterKey] !== 0) {
                continue;
            }

            if (Array.isArray(filters[filterKey])) {
                filters[filterKey].forEach((id, index) => {
                    searchParams.append(`filter[${filterKey}][${index}]`, id);
                });
                continue;
            }

            searchParams.append(`filter[${filterKey}]`, getValue(filters[filterKey]));
        }
    }

    const queryParams = searchParams.toString();
    if (!queryParams)
        return '';


    return `?${queryParams}`;

}


const getFilterParams = (queryString) => {
    const params = new URLSearchParams(queryString);
    const filterParams = {};

    const regex = /^filter\[([a-zA-Z0-9]+)\](?:\[(\d+)\])?$/;

    params.forEach((value, key) => {
        const match = regex.exec(key);
        if (match) {
            const [, type, index] = match;
            if (!filterParams[type]) {
                filterParams[type] = [];
            }
            if (index !== undefined) {
                filterParams[type][index] = value;
            } else {
                filterParams[type].push(value);
            }
        }
    });

    return filterParams;
}


export const useQueryParams = () => {

    let location = useLocation()


    let limit = null;
    let page = null;
    let sort = null;
    let filters = {};




    if (location.search) {

        const queryString = new URLSearchParams(location.search)
        const queryParams = Object.fromEntries(queryString);
        limit = (queryParams.limit) ? parseInt(queryParams.limit) : null
        page = (queryParams.page) ? parseInt(queryParams.page) : null

        if (queryParams.sort && queryParams.by) {
            sort = [];
            sort.push({ field: queryParams.by, sort: queryParams.sort })

        }
        limit = (queryParams.limit) ? parseInt(limit) : null

        // filters = getFilterParams(queryString)

        const arrRegex = new RegExp('^filter\[[a-zA-Z0-9]+\][[a-zA-Z0-9]+]$');
        const regex = new RegExp('^filter\[[a-zA-Z0-9]+\]$');

        for (const property in queryParams) {

            if (arrRegex.test(property)) {

                const match = property.match(/^filter\[([a-zA-Z0-9]+)\](?:\[(\d+)\])?$/)
                const [, key1, key2] = match;

                if (!filters[key1]) {
                    filters[key1] = [];
                }

                const integerPattern = /^-?\d+$/;

                filters[key1][key2] = integerPattern.test(queryParams[property]) ? parseInt(queryParams[property], 10) : queryParams[property]
                continue;

            }

            if (regex.test(property)) {

                filters[property.replace('filter[', '').replace(']', '')] = queryParams[property];

            }
        }




    }


    return {
        limit: limit,
        page: page,
        sort: sort,
        filters: filters
    }

}


