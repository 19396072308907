import { useTranslation } from "react-i18next"

export const useTimeConverter = () => {

    const { t } = useTranslation('main')

    const timeLeft = (seconds) => {

        if (seconds < 60) {
            return `${seconds} ${t('sec')}`
        } else {
            let min = Math.ceil(seconds / 60)
            return `${min} ${t('min')}`
        }

    }

    const timeColor = (seconds) => {

        if (seconds < 60) {
            return 'red'
        }

        if (seconds < 240) {
            return 'orange'
        }

        return 'black'
    }


    const getDate = (daysModifier) => {
        const today = new Date(); // get today's date
        return new Date(today.getTime() + (daysModifier * 24 * 60 * 60 * 1000));
    }

    const getToday = () => {
        return new Date()
    }

    const getYearBeforeFirstDay = () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 1)
        date.setDate(1)
        return date;

    }


    return { timeLeft: timeLeft, timeColor: timeColor, getDate: getDate, getYearBeforeFirstDay: getYearBeforeFirstDay, getToday: getToday }
}