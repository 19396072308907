import { Box, IconButton, useTheme } from "@mui/material"
import { Menu } from "@mui/icons-material"
import AccountMenu from "./AccountMenu"
import { Grid } from "@mui/material"
import { useContext, useState } from "react"
import { SidebarContext } from '../components/shared/SidebarContext';
import { shadows, tokens } from "../theme"



export default function Topbar() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const shadowsTokens = shadows(theme.palette.mode) 

    const [state, setContext] = useContext(SidebarContext);


    const handleClick = () => {
        setContext({ opened: !state.opened })
    }

    const handleMobileClick = () => {
        setContext({ mobileOpened: !state.mobileOpened })
    }

    return (
        <>
            <Box sx={{
                backgroundColor: colors.grey[100],
                pb: 1,
                boxShadow: shadowsTokens.small

            }} >
                <Grid container spacing={0}>
                    <Grid item xs={2}>

                        <IconButton
                            onClick={handleClick}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ ml: 1, mt: 1, display: { xs: 'none', sm: 'inline-flex' } }}

                        ><Menu /></IconButton>


                        <IconButton
                            onClick={handleMobileClick}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ ml: 1, mt: 1, display: { xs: 'inline-flex', sm: 'none' } }}

                        ><Menu />             

                        </IconButton>

                     
                    </Grid>
                    <Grid item xs={10} sx={{ pt: 1 }}>
                        <Grid container justifyContent="flex-end" sx={{ pr: 2 }} >
                            <AccountMenu />
                        </Grid>
                    </Grid>

                </Grid>



            </Box>
        </>
    )
}

