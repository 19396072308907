import api from "./config/axiosConfig";




export const login = async (username, password) => {

    const response = await api.post('login_check', {
        username: username,
        password: password
    })

    return response;

}

export const logout = async () => {
    return await api.get('logout');
}


export const profile = async () => {

    const response = await api.get('profile')

    return response;

}

export const privileges = async () => {
    const response = await api.get('privileges')

    return response;
}

