import { Grid } from '@mui/material'
import { useTranslation } from "react-i18next"
import SidebarContentTitle from '../../components/shared/SidebarContentTitle';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PaperButton from '../../components/shared/PaperButton';

export default function SettingsPage() {

    const { t } = useTranslation('main')


    return (<>
        <SidebarContentTitle title={t('menu.settings')} />

        <Grid container rowSpacing={0} sx={{ pr: 3, pb: 5, m: 0 }}>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('settings.companyGroups')}
                    url="/app/settings/company-groups"
                    icon={<FormatListBulletedOutlinedIcon />}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('settings.ideas')}
                    url="/app/settings/ideas-groups"
                    icon={<TipsAndUpdatesOutlinedIcon />}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('settings.points')}
                    url="/app/settings/points"
                    icon={<StarBorderPurple500OutlinedIcon />}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('settings.payouts')}
                    url="/app/settings/payouts"
                    icon={<NotificationsActiveOutlinedIcon />}
                />
            </Grid>


        </Grid>
    </>)
}