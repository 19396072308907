import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteRange, getList } from "../../apis/PointsRangeApi";
import StaticDataGrid from "../../components/shared/datagrid/StaticDataGrid";
import SidebarContent from "../../components/shared/SidebarContent";
import SidebarContentTitle from "../../components/shared/SidebarContentTitle";
import { useResponseHandler } from "../../hooks/ResponseHandler";
import OnePointForm from "./components/points/one-point-form/OnePointForm";
import AddForm from "./components/points/points-range/AddForm";
import useColumnsDefinition from "./components/points/points-range/config/columns";
import EditForm from "./components/points/points-range/EditForm";
import { getMaxPointsRange } from "./components/points/points-range/helpers/utils";


export default function PointsSettingsPage() {

    const { t } = useTranslation('main')
    const [isLoading, setIsLoading] = useState(true)
    const [responseCode, setResponseCode] = useState()
    const [data, setData] = useState([])
    const [handleError, handleSuccess] = useResponseHandler()
    const [editElement, setEditElement] = useState(null)

    const fixDataForDatagrid = (objArr) => {
        let maxToPoints = getMaxPointsRange(objArr);

        for (const key in objArr) {
            objArr[key].isLast = objArr[key].toPoints === maxToPoints
        }

        return objArr;
    }


    const updateData = (obj) => {
        const newData = data.filter((o) => o.id !== obj.id);
        newData.push(obj)
        setData(fixDataForDatagrid(newData))
    }


    const handleDelete = (params) => {

        const deleteRangeFn = async () => {
            await deleteRange(params.id).catch((e) => {
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response)
                    setData(fixDataForDatagrid(data.filter((obj) => obj.id !== params.id)))
                }
            })
        }

        deleteRangeFn()

    }


    useEffect(() => {

        const controller = new AbortController();

        const getPointsRanges = async () => {
            await getList(controller).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                response && setData(fixDataForDatagrid(response.data))
                setIsLoading(false)
            })
        }

        getPointsRanges()

        return () => controller.abort()

    }, [])

    return (
        <SidebarContent isLoading={isLoading} responseCode={responseCode} noMargins={true} >
            <SidebarContentTitle title={t('settings.points')} />



            <Grid container>

            <Grid item xs={12} lg={4}>
                    <OnePointForm />
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Paper sx={{ m: 3 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={{ p: 2 }}>{t('pointsRanges')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AddForm updateData={updateData} data={data} />
                                <EditForm updateData={updateData} data={data} editElement={editElement} onEditClose={() => setEditElement(null)} />
                            </Grid>
                        </Grid>
                        <StaticDataGrid
                            columns={useColumnsDefinition({
                                onDelete: handleDelete,
                                onEdit: setEditElement
                            })}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'range', sort: 'asc' }],
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,

                                    },
                                },
                            }}
                            rows={data}
                        />
                    </Paper>
                </Grid>

               
            </Grid>
        </SidebarContent>
    )
}