import { useContext } from "react"
import { AuthContext } from './../../../components/shared/AuthContext';

export const useRequestStatus = () => {

    const [state, setContext] = useContext(AuthContext);

    const getNewStatusId = (currentStatus) => {
        const newStatus = state.dictionary.payoutRequestStatuses.find(status => status.id !== currentStatus.id)
        return newStatus.id
    }

    return { getNewStatusId }

}