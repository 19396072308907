import { Navigate, useRoutes } from 'react-router-dom';
import Guard from './components/shared/Guard';


// layouts
import EmptyLayout from './layouts/EmptyLayout'
import SidebarLayout from './layouts/SidebarLayout'

// pages
import DashboardPage from './pages/dashboard/DashboardPage';
import IdeasPage from './pages/ideas/IdeasPage';
import IdeaPage from './pages/ideas/IdeaPage';
import AddIdeaPage from './pages/ideas/AddIdeaPage';
import EditIdeaPage from './pages/ideas/EditIdeaPage';
import PointsHistoryPage from './pages/points-history/PointsHistoryPage';

import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import Page404 from './pages/Page404'
import Error from './pages/Error'
import NewPayoutRequestPage from './pages/payout-request/NewPayoutRequestPage';
import PayoutRequestsPage from './pages/payout-request/PayoutRequestsPage';
import PasswordPage from './pages/password/PasswordPage';
import SettingsPage from './pages/settings/SettingsPage';
import CompanyGroupsSettingsPage from './pages/settings/CompanyGroupsSettingsPage';
import IdeasGroupsSettingsPage from './pages/settings/IdeasGroupsSettingsPage';
import PointsSettingsPage from './pages/settings/PointsSettingsPage';
import PayoutsSettingsPage from './pages/settings/PayoutsSettingsPage';
import UsersPage from './pages/users/UsersPage';
import AddUserPage from './pages/users/AddUserPage';
import EditUserPage from './pages/users/EditUserPage';
import UserPage from './pages/users/UserPage';
import MyProfilePage from './pages/my-profile/MyProfilePage';
import ReportsPage from './pages/reports/ReportsPage';
import ReportByTimePage from './pages/reports/ReportByTimePage';
import ReportByEmployeesPage from './pages/reports/ReportByEmployeesPage';
import ReportByStatusPage from './pages/reports/ReportByStatusPage';
import PointsRankPage from './pages/points-rank/PointsRankPage';



// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/app',
      element: <SidebarLayout />,
      children: [
        { element: <Navigate to="/app/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'ideas', element: <IdeasPage /> },
        { path: 'ideas/:id', element: <IdeaPage /> },
        { path: 'ideas/add', element: <AddIdeaPage /> },
        { path: 'ideas/edit/:id', element: <Guard granted={['roleIdeaManageAll']}><EditIdeaPage /></Guard> },
        { path: 'points-history', element: <Guard granted={['rolePointsHistory']}><PointsHistoryPage /></Guard> },
        { path: 'payout-request', element: <Guard granted={['rolePayoutRequest']}><PayoutRequestsPage /></Guard> },
        { path: 'payout-request/new', element: <Guard granted={['rolePayoutRequest']}><NewPayoutRequestPage /></Guard> },
        { path: 'password', element: <PasswordPage /> },
        { path: 'settings', element: <Guard granted={['roleSettings']}><SettingsPage /></Guard> },
        { path: 'settings/company-groups', element: <Guard granted={['roleSettings']}><CompanyGroupsSettingsPage /></Guard> },
        { path: 'settings/ideas-groups', element: <Guard granted={['roleSettings']}><IdeasGroupsSettingsPage /></Guard> },
        { path: 'settings/points', element: <Guard granted={['roleSettings']}><PointsSettingsPage /></Guard> },
        { path: 'settings/payouts', element: <Guard granted={['roleSettings']}><PayoutsSettingsPage /></Guard> },
        { path: 'users', element: <Guard granted={['roleUsersManage']}><UsersPage /></Guard> },
        { path: 'users/:id', element: <Guard granted={['roleUsersManage']}><UserPage /></Guard> },
        { path: 'users/add', element: <Guard granted={['roleUsersManage']}><AddUserPage /></Guard> },
        { path: 'users/edit/:id', element: <Guard granted={['roleUsersManage']}><EditUserPage /></Guard> },
        { path: 'reports', element: <Guard granted={['roleReport']}><ReportsPage /></Guard> },
        { path: 'reports/by-time', element: <Guard granted={['roleReport']}><ReportByTimePage /></Guard> },
        { path: 'reports/by-employees', element: <Guard granted={['roleReport']}><ReportByEmployeesPage /></Guard> },
        { path: 'reports/by-status', element: <Guard granted={['roleReport']}><ReportByStatusPage /></Guard> },
        { path: 'reports/by-points', element: <Guard granted={['roleReport']}><PointsRankPage /></Guard> },
        { path: 'my-profile', element: <MyProfilePage /> },
       
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <EmptyLayout />,
      children: [
        { element: <Navigate to="/app/dashboard" />, index: true },
        { path: 'error', element: <Error /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Page404 /> },
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
