
import { useContext } from "react";
import { logout } from "../apis/AuthApi";
import { AuthContext } from '../components/shared/AuthContext';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import FullPageLoader from '../components/shared/FullPageLoader';


export default function LogoutPage() {

    const [state, setContext] = useContext(AuthContext);

    const handleErrors = (error) => {
        navigate('/error')
    }

    let navigate = useNavigate()
    useEffect(() => {

        const clearCookies = async () => {
            const response = await logout().catch(handleErrors)

            if (response) {
                setContext({ isAuth: false, autoLogout: true, user: null, privileges: null, dictionary: null })
                localStorage.removeItem("isAuth")
                localStorage.removeItem("autoLogout")
                sessionStorage.removeItem("autoLogout")
                navigate('/login')
            }

        }

        clearCookies();

    }, []);





    return (
        <FullPageLoader />
    );
}


