import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function CanvasArea(props) {

  
    const colorRef = useRef(props.color)
    // const historyRef = useRef(props.historyList)
    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;
    let history = []

    const clear = () => {
        const canvas = props.canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#ffffff'; // Set fill color
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    const updateHistoryList = (item) => {
        props.historyList.current = [...props.historyList.current, item]

    } 



    useEffect(() => {
        const canvas = props.canvasRef.current;
        const context = canvas.getContext('2d');
      

        const addHistory = (historyItem) => {
           history = [...history, historyItem]
        }

        

        const startDraw = (e) => {
            isDrawing = true;
            lastX = e.offsetX;
            lastY = e.offsetY;
            
        }

        const draw = (e) => {
           
            

            if (isDrawing) {
                context.beginPath();
                context.moveTo(lastX, lastY);
                context.lineTo(e.offsetX, e.offsetY);
                
                context.strokeStyle = colorRef.current
                context.lineWidth = 5;
                context.lineCap = "round";
                context.stroke();

                addHistory({lastX: lastX, lastY: lastY, offsetX: e.offsetX, offsetY: e.offsetY, color: colorRef.current})

                lastX = e.offsetX;
                lastY = e.offsetY;


            }
        }


        


        const stopDraw = () => {
            isDrawing = false;
            updateHistoryList(history)
            history = []
           
        }

        const disableDraw = () => {
            isDrawing = false;
            
        }

        canvas.addEventListener('mousedown', startDraw);
        canvas.addEventListener('touchstart', startDraw);

        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('touchmove', function (e) {

        

            if (isDrawing) {
          
                var touch = e.touches[0];

            

                context.beginPath();
                context.moveTo(lastX, lastY);
                context.lineTo(touch.clientX, touch.clientY-50);

                context.strokeStyle = colorRef.current
                context.lineWidth = 5;
                context.lineCap = "round";
                context.stroke();
                addHistory({lastX: lastX, lastY: lastY, offsetX: touch.offsetX, offsetY: touch.offsetY-50, color: colorRef.current})


                lastX = touch.clientX;
                lastY = touch.clientY-50;
            }

            

        });

        canvas.addEventListener('mouseup', stopDraw);
        canvas.addEventListener('touchend', stopDraw);

        canvas.addEventListener('mouseleave', disableDraw);
    }, []);

    useEffect(() => {
        colorRef.current = props.color
    }, [props.color])

    useEffect(() => {
        clear()
    }, [props.clear])


    useEffect(() => {
        
        clear()

        const canvas = props.canvasRef.current;
        const context = canvas.getContext('2d');

        

        props.historyList.current.map((element) => {
            element.map((item) => { 
                
                context.beginPath();
                context.moveTo(item.lastX, item.lastY);
                context.lineTo(item.offsetX, item.offsetY);
                
                context.strokeStyle = item.color
                context.lineWidth = 5;
                context.lineCap = "round";
                context.stroke();            

                lastX = item.offsetX;
                lastY = item.offsetY;


            })

          });


    }, [props.redraw])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <canvas
                ref={props.canvasRef}
                width={window.innerWidth - 10}
                height={window.innerHeight - 80}
                style={{ border: "2px dotted #ccc", cursor: "crosshair" }}

            />
        </Box>
    );

}