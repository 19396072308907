import { useContext } from "react"
import { AuthContext } from "../components/shared/AuthContext"

export const useIdeaStatuses = () => {
    const [authState] = useContext(AuthContext)

    const KEYS = {
        CONSULTATION: 'consultation',
        ACCEPTED: 'accepted',
        REFUSED: 'refused',
        DEPLOYED: 'deployed'
    }

    const statuses = authState.dictionary.ideaStatuses
    let statusesIds = {}
    for (const statusKey in statuses) {
        statusesIds[statuses[statusKey].type] = statuses[statusKey].id
    }

    return {
        statuses: statuses,
        statusesIds: statusesIds,
        KEYS: KEYS
    }

}