import { useTranslation } from "react-i18next";
import { dateFormat, datetimeFormat } from "../../../../components/shared/Utils";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Link, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StatusLabel from "../StatusLabel";
import IsAllowed from "../../../../components/shared/IsAllowed";


const css = { align: 'center', headerAlign: 'center' }
const sortingOrder = { sortingOrder: ['desc', 'asc'] }

export const useColumnsDefinition = () => {

    const { t } = useTranslation('main')
    let navigate = useNavigate()

    const handleTitleClick = (params) => {
        return navigate('/app/ideas/' + params.id)
    }

    const handleEditClick = (params) => {
        return navigate('/app/ideas/edit/' + params.id)
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, ...css, ...sortingOrder, },
        { field: 'ideaNr', headerName: t('ideaNr'), width: 90, ...css, ...sortingOrder, },
        {
            field: 'title',
            headerName: t('ideaTitle'),
            flex: 1,
            wrapText: true,
            minWidth: 150,
            ...sortingOrder,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                <Link sx={{ cursor: 'pointer' }} onClick={() => handleTitleClick(params.row)} >{params.row.title}</Link>
                </div>
            )
        },
        {
            field: 'supervisor',
            headerName: t('supervisior'),
            wrapText: true,
            minWidth: 170,
            ...sortingOrder,
            renderCell: (params) => {
                let content = '-'
                if(params.row.supervisor) {
                    content = <>{params.row.supervisor?.firstName} {params.row.supervisor?.lastName}</>
                } 

                return <div>{content}</div>
            
            }
        },
        {
            field: 'u.symbol',
            headerName: t('employeeSymbol'),
            width: 130,
            ...css,
            ...sortingOrder,
            valueGetter: (params) =>
                `${params.row.user.symbol || '-'}`,
        },
        {
            field: 'created',
            headerName: t('ideaCreated'),
            width: 110,
            ...css,
            ...sortingOrder,
            valueFormatter: ({ value }) => dateFormat(value),
        },

        {
            field: 'modified',
            headerName: t('ideaModified'),
            width: 130,
            ...css,
            ...sortingOrder,
            valueFormatter: ({ value }) => datetimeFormat(value),
        },

        {
            field: 'cg.name',
            headerName: t('productionGroup'),
            width: 130,
            ...css,
            ...sortingOrder,
            valueGetter: (params) => `${params.row.companyGroup.name || ''}`,
        },
        {
            field: 'ids.name',
            headerName: t('status'),
            width: 130,
            ...css,
            ...sortingOrder,
            valueGetter: (params) => `${params.row.status.name || ''}`,
            renderCell: (params) => (
                <StatusLabel status={params.row.status} />
            )
        },
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            width: 80,
            renderCell: (params) => (
                <IsAllowed granted="roleIdeaManageAll">
                    <Tooltip title={t('edit')} placement="left" >
                        <IconButton onClick={() => handleEditClick(params.row)} color="primary" >
                            <EditOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </IsAllowed>
            ),

            ...css,
            ...sortingOrder,
        },
    ];

    return columns;
}