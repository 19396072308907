import { Paper, Box, Typography, Button } from '@mui/material';
import { useColumnsDefinition } from './config/columns';
import { download, getList } from '../../../apis/UsersApi';
// import IdeasFilter from '../../ideas/components/IdeasFilters';
import { DataGridContext } from '../../../components/shared/datagrid/DataGridContext';
import ServerDataGrid from '../../../components/shared/datagrid/ServerDataGrid';
import ActiveFilters from '../../../components/shared/form/AciveFilters';
import { useEffect, useContext } from 'react';
import UsersFilters, { useFieldsConfiguration, defaultFilters } from './UsersFilters';
import { useTranslation } from 'react-i18next';
import { useResponseHandler } from '../../../hooks/ResponseHandler';
import fileDownload from 'js-file-download';







export default function UsersList(props) {

    const { t } = useTranslation('main')

    const [dataGridState, setDataGridContext] = useContext(DataGridContext)
    const [handleError] = useResponseHandler()


    const handleDownloadClick = (params) => {

        const downloadRequest = async () => {

            await download(dataGridState.filters).catch((e) => {
                handleError(e)

            }).then((res) => {

                res && fileDownload(res.data, `Lista_uzytkownikow.xlsx`)
            })
        }

        downloadRequest()

    }


    useEffect(() => {

        const getUsers = async () => {

            if (!dataGridState.reload)
                return false;

            setDataGridContext({ isLoading: true })
            const result = await getList(dataGridState.limit, dataGridState.page, dataGridState.sort, dataGridState.filters);

            if (result) {
                setDataGridContext({
                    isLoading: false,
                    rows: result.data.data,
                    totalRows: result.data.paginator.totalRecords,
                    reload: false
                })
            } else {
                setDataGridContext({ reload: false })
            }
        }

        getUsers();

    }, [dataGridState.reload])


    return (
        <>

            <Paper elevation={2} sx={{ ml: 3, mt: 3 }}>

                <Box display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 2, pt: 2 }}>
                    <Button  size="small" onClick={handleDownloadClick}>{t('exportToExcel')}</Button>
                    <UsersFilters />
                </Box>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <ActiveFilters defaultFilters={defaultFilters} fields={useFieldsConfiguration()} />
                </Box>
                <Box >
                    <ServerDataGrid
                        columns={useColumnsDefinition()}

                    />

                </Box>

            </Paper>

        </>
    );
}