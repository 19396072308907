import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Router from './routes';
import AuthContextProvider from './components/shared/AuthContextProvider';
import './App.css';


function App() {

  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        <AuthContextProvider>
          <CssBaseline />
          <Router />
        </AuthContextProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

export default App;
