
import { useContext, useState, useEffect } from "react"
import { SidebarContext } from '../components/shared/SidebarContext';
import { IconButton, Grid, useTheme, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import SidebarItem from "./SidebarItem";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import { tokens } from "../theme";
import { AuthContext } from "./shared/AuthContext";



export default function SideBar() {

    const { t } = useTranslation('main')
    const [state, setContext] = useContext(SidebarContext);
    const [authState] = useContext(AuthContext)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const [sidebarStyles, setSidebarStyles] = useState({
        backgroundColor: colors.secondary[500],
        color: colors.grey[100],
        width: { xs: 0, sm: 60 },
        height: '100vh',
        transition: 'all .3s ease-in-out',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: { xs: 'absolute', sm: 'relative' },
        zIndex: 200,

    });



    const handleClick = () => {
        setContext({ mobileOpened: !state.mobileOpened })
    }

    useEffect(() => {

        setSidebarStyles({
            ...sidebarStyles, ...{
                width: {
                    xs: (state.mobileOpened ? '100%' : 0),
                    sm: (state.opened ? 260 : 60),
                }
            }
        })

    }, [state]);


    return (

        <Box className="sidebar" sx={sidebarStyles}>
            <Grid container justifyContent="space-between"  >

                <Box sx={{ p: 2, overflow: 'hidden', width: ((state.opened || state.mobileOpened) ? 'auto' : '45px')  }}>
                    <img src="/img/kaizen_logo_white.svg" alt="" className="sidebar-logo" />
                </Box>
                <IconButton

                    onClick={handleClick}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mt: 1, mr: 1, display: { xs: "inline-flex", sm: "none" } }}


                ><CloseIcon /></IconButton>
            </Grid>


            <SidebarItem to="/app/dashboard" text={t('menu.desktop')}  >
                <HomeOutlinedIcon />
            </SidebarItem>

            <SidebarItem to="/app/ideas" text={t('menu.ideas')} granted="roleIdea" >
                <TipsAndUpdatesOutlinedIcon />
            </SidebarItem>

            <SidebarItem to="/app/points-history" text={t('menu.points')} granted="rolePoints" >
                <StarOutlineOutlinedIcon />
            </SidebarItem>

            <SidebarItem to="/app/payout-request" text={t('menu.payoutsRequests')} granted="rolePayoutRequest" >
                <ArticleOutlinedIcon />
            </SidebarItem>

            <SidebarItem to="/app/reports" text={t('menu.reports')} granted="roleReport" >
                <InsightsOutlinedIcon />
            </SidebarItem>

            <SidebarItem to="/app/users" text={t('menu.users')} granted="roleUsersManage" >
                <PeopleAltOutlinedIcon />
            </SidebarItem>

            <SidebarItem to="/app/settings" text={t('menu.settings')} granted="roleSettings" >
                <SettingsSuggestOutlinedIcon />
            </SidebarItem>



        </Box>
    )

}