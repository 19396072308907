import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getUserStats } from "../../apis/StatsApi";
import DeleteConfirmButton from "../../components/shared/DeleteConfirmButton";
import IsAllowed from "../../components/shared/IsAllowed";
import SidebarContent from "../../components/shared/SidebarContent";
import YourIdeas from "../dashboard/components/YourIdeas";
import UserDetails from "./components/UserDetails";
import UserPoints from "./components/UserPoints";
import { deleteUser } from '../../apis/UsersApi';
import { useResponseHandler } from '../../hooks/ResponseHandler';

export default function UserPage() {

    let { id } = useParams();

    const { t } = useTranslation('main')
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [responseCode, setResponseCode] = useState(0)
    const [data, setData] = useState(null)
    const [handleError, handleSuccess] = useResponseHandler()
    const [modified, setModified] = useState(false)


    useEffect(() => {

        if (!isLoading)
            setIsLoading(true)

        const controller = new AbortController()

        const getUserProfile = async (userId) => {
            await getUserStats(userId, controller).catch((e) => {
                setResponseCode(e.response.status)
            }).then((response) => {
                if (response) {
                    setData(response.data)
                    setResponseCode(response.status)

                }

                setIsLoading(false)
            })

        }

        getUserProfile(id)

        return () => controller.abort()

    }, [modified])



    const onDelete = () => {
        const doDelete = async (userId) => {
            await deleteUser(userId).catch((e) => {
                setResponseCode(e.response.status)
                handleError(e)
            }).then((response) => {
                if (response) {
                    handleSuccess(response);
                    return navigate('/app/users')
                }

                setIsLoading(false)
            })

        }

        doDelete(id)
    }


    return (

        <SidebarContent isLoading={isLoading} responseCode={responseCode}>


            <Grid container sx={{ pb: 3 }}>
                <Grid item xs={6} sm={7} alignItems="center">
                    <Typography variant="h1">{t('userDetails')}</Typography>
                </Grid>

                <Grid item xs={6} sm={5} sx={{ textAlign: 'right' }}>

                    <IsAllowed granted="roleUsersManage">
                        <Tooltip title={t('edit')} >
                            <IconButton sx={{ mr: 2 }} onClick={() => { return navigate(`/app/users/edit/${id}`) }} color="primary" >
                                <EditOutlinedIcon />
                            </IconButton>
                        </Tooltip>


                        <DeleteConfirmButton onDelete={onDelete} />
                    </IsAllowed>

                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} >
                    <UserDetails user={data?.user} points={data?.points} onSaldoUpdate={() => { setModified(!modified)}} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <UserPoints points={data?.points}></UserPoints>
                    <Box sx={{ mt: 3 }}  >
                        <YourIdeas ideas={data?.ideas} title={t('userIdeas')} />
                    </Box>
                </Grid>
            </Grid>

        </SidebarContent>


    )
}