import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useIsAllowed } from "../../../../components/shared/IsAllowed";
import { currency, dateFormat } from "../../../../components/shared/Utils";

import PayoutStatusLabel from './../PayoutStatusLabel';




const css = { align: 'center', headerAlign: 'center' }
const sortingOrder = { sortingOrder: ['desc', 'asc'] }

export const useColumnsDefinition = (handleDownloadClick, handleStatusChange) => {

    const { t } = useTranslation('main')
    let navigate = useNavigate()
    const isAllowed = useIsAllowed()  

     

    let protectedColumns = []
    let downloadButton = []

    if (isAllowed('rolePayoutRequestViewAll')) {
        protectedColumns = [
            {
                field: 'u.lastName',
                headerName: t('fullname'),
                flex: 1,
                ...css,
                ...sortingOrder,
                valueGetter: (params) =>
                    `${params.row.user.lastName} ${params.row.user.firstName}`,
            },
            {
                field: 'u.symbol',
                headerName: t('employeeSymbol'),
                flex: 1,
                ...css,
                ...sortingOrder,
                valueGetter: (params) =>
                    `${params.row.user.symbol || '-'}`,
            }
        ]

        downloadButton = [
            {
                field: 'actions',
                headerName: t('download'),
                sortable: false,
                disableColumnMenu: true,
                width: 80,
                renderCell: (params) => (
                   
                        <Tooltip title={t('download')} placement="left" >
                            <IconButton onClick={() => handleDownloadClick(params.row)} color="primary" >
                                <FileDownloadOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                   
                ),
    
                ...css,
                ...sortingOrder,
            },
        ]
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 70, ...css, ...sortingOrder, },
        ...protectedColumns,
        
        {
            field: 'created',
            headerName: t('payoutRequestDate'),
            width: 180,
            ...css,
            ...sortingOrder,
            valueFormatter: ({ value }) => dateFormat(value),

        },

        {
            field: 'prs.name',
            headerName: t('status'),
         
            width: 130,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <PayoutStatusLabel request={params.row}  />
            )

        },
        {
            field: 'points',
            headerName: t('pointsToGet'),
            width: 140,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <strong>{params.row.points} {t('pts')}</strong>
            )

        },
        {
            field: 'pointsValue',
            headerName: t('pointsToGetValue'),
            width: 130,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <strong>{currency(params.row.pointsValue)}</strong>
            )

        },
        ...downloadButton




    ];

    return columns;
}