import { Grid } from "@mui/material"
import DateFieldElement from "./DateFieldElement"

export default function FromToGroup(props) {

   


    return (
        <Grid container >
            <Grid item xs={5}>
                <DateFieldElement field={props.field.fromField} register={props.register} control={props.control} />
            </Grid>
            <Grid item xs={2} sx={{ pt: 1, textAlign: 'center' }}>-</Grid>
            <Grid item xs={5}>
                <DateFieldElement field={props.field.toField} register={props.register} control={props.control} />
            </Grid>
        </Grid>
    )
}