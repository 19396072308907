import React, { useEffect } from "react";
import { useState } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";

function AuthContextProvider({ children }) {

    let navigate = useNavigate()

    const [state, setState] = useState({
        isAuth: localStorage.getItem("isAuth") === 'true',
        autoLogout: localStorage.getItem("autoLogout") === 'true',
        user: null,
        privileges: null,
        dictionary: null,
    });

    const setContext = (newState) => {
        setState({ ...state, ...newState });
    };

    useEffect(() => {
        if (localStorage.getItem("autoLogout") === 'true' && !sessionStorage.getItem("autoLogout")) {
            navigate('/logout')
        }

    }, [])

    return (
        <AuthContext.Provider value={[state, setContext]}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
