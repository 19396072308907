import { Chip } from "@mui/material";

export default function StatusLabel(props) {

    let color = 'default';
    switch (props.status.type) {
        case 'refused':
            color = 'error';
            break;
        case 'accepted':
            color = 'success';
            break;
        case 'deployed':
            color = 'info';
            break;
    }

    return (<Chip size="small" label={props.status.name} color={color} />)

}