import { Box, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getList } from '../../../apis/PointsHistoryApi';
import { DataGridContext } from '../../../components/shared/datagrid/DataGridContext';
import ServerDataGrid from '../../../components/shared/datagrid/ServerDataGrid';
import ActiveFilters from '../../../components/shared/form/AciveFilters';
import { defaultFilters, useFieldsConfiguration } from '../../points-history/components/PointsHistoryFilters';
import { useColumnsDefinition } from './config/config';
import PoinntsHistoryFilters from './PointsHistoryFilters';







export default function PointsHistoryList(props) {

    const { t } = useTranslation('main')

    const [dataGridState, setDataGridContext] = useContext(DataGridContext)
    const [totalPoints, setTotalPoints] = useState(null)



    useEffect(() => {

        const getPointsHistory = async () => {

            if (!dataGridState.reload)
                return false;

            setDataGridContext({ isLoading: true })
            const result = await getList(dataGridState.limit, dataGridState.page, dataGridState.sort, dataGridState.filters)

            if (result) {
                setTotalPoints(result.data.pointsSum)
             
                setDataGridContext({
                    isLoading: false,
                    rows: result.data.results.data,
                    totalRows: result.data.results.paginator.totalRecords,
                    reload: false
                })
            } else {
                setDataGridContext({ reload: false })
            }
        }

        getPointsHistory();

    }, [dataGridState.reload])

  

    return (
        <>

            <Paper elevation={2} sx={{ ml: 3, mt: 3 }}>

                <Box display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 2, pt: 2 }}>
                    <Box display="flex" alignItems="center"><Typography variant='h6'>{t('pointsSum')}:</Typography> <Typography variant='h5' sx={{ ml: 1 }}>{totalPoints}</Typography></Box>

                    <PoinntsHistoryFilters />
                </Box>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <ActiveFilters defaultFilters={defaultFilters} fields={useFieldsConfiguration()} />
                </Box>
                <Box >
                    <ServerDataGrid
                        columns={useColumnsDefinition()}

                    />

                </Box>

            </Paper>

        </>
    );
}