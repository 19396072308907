import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useIdeaStatuses } from "../../../../hooks/IdeaStatuses";

export const useIdeaFormValidationSchema = (editSchema) => {

    const { t } = useTranslation('main')

    const { statuses, statusesIds, KEYS } = useIdeaStatuses();


    const title = yup.string()
        .required(t('err.required'))
        .min(2, t('err.min', { min: 2 }))
        .max(255, t('err.max', { max: 255 }));

    const problemDescription = yup.string()
        .required(t('err.required'))
        .min(20, t('err.min', { min: 20 }));

    const solution = yup.string()
        .required(t('err.required'))
        .min(20, t('err.min', { min: 20 }));

    const companyGroup = yup.string()
        .required(t('err.required'))
        .min(1, t('err.required'))

    const status = yup.string()
        .required(t('err.required'));

    const ideaGroup = yup.string().when('status', {
        is: (value) => value === String(statusesIds[KEYS.DEPLOYED]),
        then: yup.string().required(t('err.required')),
        otherwise: yup.string()
    });



    const pointsForAdd = yup.number().when(
        'status', {
        is: (value) => value === String(statusesIds[KEYS.CONSULTATION]),
        then: yup.number().typeError(t('err.notInteger'))
            .nullable()
            .min(0, t('err.minValue', { min: 0 }))
            .max(1000, t('err.maxValue', { max: 1000 }))
            .transform((_, val) => (val !== "" ? Number(val) : null)),
        otherwise: yup.number().typeError(t('err.notInteger'))
            .min(0, t('err.minValue', { min: 0 }))
            .max(1000, t('err.maxValue', { max: 1000 }))
            .transform((_, val) => (val !== "" ? Number(val) : null))
    }
    )
        ;


    const plannedImplementationDate = yup.date()
        .nullable()
        .notRequired()
        .typeError(t('err.invalidDate'));


    const implementationDate = yup.date().when('status', {
        is: (value) => value === String(statusesIds[KEYS.DEPLOYED]),
        then: yup.date()
            .typeError(t('err.invalidDate')),
        otherwise: yup.date()
            .nullable()
            .notRequired()
            .typeError(t('err.invalidDate'))
    })


    const supervisor = yup.mixed()
        .transform((value) => (value ? value.id : null))




    let defaultIdeaSchema = {
        title: title,
        problemDescription: problemDescription,
        solution: solution,
        companyGroup: companyGroup
    }




    if (editSchema) {
        defaultIdeaSchema = {
            status: status,
            ideaGroup: ideaGroup,
            pointsForAdd: pointsForAdd,
            plannedImplementationDate: plannedImplementationDate,
            implementationDate: implementationDate,
            supervisor: supervisor,
            ...defaultIdeaSchema
        }

    }

    return yup.object({
        idea: yup.object(defaultIdeaSchema)
    }).required();

}