import { Box, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ManageDialog from "./ManageDialog"
import { create } from "../../../../../apis/PointsRangeApi"
import { getMaxPointsRange } from "./helpers/utils"


export default function AddForm(props) {

    const { t } = useTranslation('main')
    const [open, setOpen] = useState(false)

    const getNextRagePoints = () => {
        return getMaxPointsRange(props.data) + 10;
    }

    return (
        <>
            <Box sx={{ py: 1, px: 1 }} textAlign="right">
                <Button onClick={() => setOpen(true)}>{t('addNewRange')}</Button>
            </Box>
            <ManageDialog
                open={open}
                close={() => setOpen(false)}
                dialogTitle={t('addNewRange')}
                defaultValues={{ fromPoints: getNextRagePoints(), toPoints: getNextRagePoints() }}
                save={create}
                updateData={props.updateData}
                data={props.data}
            />
        </>
    )
}