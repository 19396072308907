import { Outlet } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../components/shared/AuthContext';
import { profile, privileges } from '../apis/AuthApi';
import { dictionary } from '../apis/DictionaryApi';
import FullPageLoader from '../components/shared/FullPageLoader';
import { useNavigate } from 'react-router-dom';
import SideBar from '../components/SideBar';
import Topbar from '../components/TopBar';
import SidebarContextProvider from '../components/shared/SidebarContextProvider';
import Box from '@mui/material/Box';
import { SnackbarProvider } from 'notistack';



export default function DashboardLayout() {

    const [state, setContext] = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const handleError = (error) => {
        if (error?.response?.status === 401) {
            navigate('/login');
            return;
        }
        navigate('/error');


    };

    useEffect(() => {

        if (!state.isAuth) {
            navigate('/login');
        }

        const getProfile = async () => {
            const profileResult = await profile().catch(handleError);

            if (!profileResult)
                return;

            const privilegesResult = await privileges().catch(handleError);
            if (!privilegesResult)
                return;

            const dictionaryResult = await dictionary().catch(handleError);
            if (!dictionaryResult)
                return;

            setContext({
                user: profileResult?.data?.user,
                privileges: privilegesResult?.data?.privileges,
                dictionary: dictionaryResult?.data,
            });

        }

        if (!state.user || !state.privileges || state.dictionary)
            getProfile();

    }, [])


    useEffect(() => {
        if (state.user && state.privileges && state.dictionary)
            setIsLoading(false);


    }, [state])


    if (isLoading) {
        return (<FullPageLoader vh="true" />)
    }





    const containerStyles = {
        display: { xs: 'block', sm: 'flex' }
    }



    return (
        <SidebarContextProvider>
            <SnackbarProvider maxSnack={3}>
                <Box height="100vh" position="relative" sx={containerStyles}>

                    <SideBar />

                    <Box height="100vh" flex="1" sx={{ overflowY: 'auto' }}>
                        <Topbar />
                        <Outlet />
                    </Box>
                </Box>
            </SnackbarProvider>
        </SidebarContextProvider>


    );
}