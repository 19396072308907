import FromToGroup from "./element/FromToGroup"
import MultiSelectFieldElement from "./element/MultiSelectFieldElement"
import SelectFieldElement from "./element/SelectFieldElement"
import TextFieldElement from "./element/TextFieldElement"

export default function FormElement(props) {

    switch (props.field.type) {
        case 'text':
            return (<TextFieldElement field={props.field} register={props.register} control={props.control} />)
        case 'select':
            return (<SelectFieldElement field={props.field} register={props.register} control={props.control} />)
        case 'multiselect':
            return (<MultiSelectFieldElement field={props.field} register={props.register} control={props.control} />)
        case 'fromtogroup':
            return (<FromToGroup field={props.field} register={props.register} control={props.control} />)
    }




}