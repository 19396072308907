
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { downloadCredentials, updatePassword } from '../../../apis/UsersApi';
import { useResponseHandler } from '../../../hooks/ResponseHandler';
import fileDownload from 'js-file-download';
import { DialogTitle } from '@mui/material';
import PasswordInputs from './PasswordInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserFormValidationSchema } from './config/ValidationSchema';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';


export default function ChangePasswordDialog(props) {


    const { t } = useTranslation('main')
    const defaultValues = { password: '', confirmPassword: '' }

    const { control, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm({ defaultValues: defaultValues, resolver: yupResolver(useUserFormValidationSchema('password')) });
    const [isLoading, setIsLoading] = useState(false)
    const [handleError, handleSuccess] = useResponseHandler()

    const onSubmit = (data) => {

        setIsLoading(true)
        const updateUserPassword = async (userId, passData) => {
            await updatePassword(props.userId, passData).catch((e) => {
                handleError(e)
            }).then((response) => {

                if (response) {
                    handleSuccess(response)
                    reset(defaultValues)
                    props.onPasswordChanged(passData)
                }

                setIsLoading(false)
            })
        }

        updateUserPassword(props.userId, data)
    }


    const handleCancel = () => {
        props.onClose()
    }







    return (
        <Dialog
            maxWidth="xs"
            open={props.open}
        >
            <DialogTitle>
                {t('changePassword')}
            </DialogTitle>

            <DialogContent>
                <PasswordInputs
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button autoFocus onClick={handleCancel}>{t('cancel')}</Button>
                <LoadingButton variant='contained' loading={isLoading} onClick={handleSubmit(onSubmit)}>{t('save')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );



}