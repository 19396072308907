import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Grid, Paper, TextField, InputAdornment, Button, Box, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getOnePointValue, update } from "../../../../../apis/SettingsApi";
import { useResponseHandler } from "../../../../../hooks/ResponseHandler";

export default function OnePointForm() {

    const { t } = useTranslation('main')

    const [isLoading, setIsLoading] = useState(true)
    const [btnIsLoading, setBtnIsLoading] = useState(false)
    const [data, setData] = useState(null)
    const [handleError, handleSuccess] = useResponseHandler()



    const schema = yup.object({
        onePointValue: yup.string(t('err.integer'))
            .required(t('err.required'))
            .test("positive", t('err.greatherThanOrEqual', { min: 0 }), val => Number(val) >= 0)
            .transform((_, val) => (val !== "" ? Number(val).toFixed(2) : ''))


    }).required();

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });





    const onSubmit = async (formData) => {



        setBtnIsLoading(true)

        await update(formData).catch((e) => {
            handleError(e)
        }).then((response) => {

            if (response) {
                handleSuccess(response)
                setData(formData)
            }
            setBtnIsLoading(false)
        })
    }

    useEffect(() => {
        reset(data)
    }, [data])

    useEffect(() => {

        const controller = new AbortController();

        const getSetting = async () => {
            await getOnePointValue(controller).catch((e) => {
                handleError(e)
            }).then((response) => {
                response && setData({ onePointValue: response.data.setting.settingValue })
                setIsLoading(false)
            })
        }

        getSetting()

        return () => controller.abort()

    }, [])

    if (isLoading) {
        return (
            <Grid container>
                <Grid item xs={12} >
                    <Paper sx={{ m: 3, p: 5, textAlign: 'center' }}>
                        <CircularProgress />
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} >
                <Paper sx={{ m: 3, px: 3 }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typography variant="h6" sx={{ py: 2 }}>{t('onePointValue')}</Typography>

                        <TextField
                            {...register('onePointValue')}
                            fullWidth
                            size="small"
                            type="number"
                            label={t('onePointValueLabel')}
                            error={!!errors.onePointValue}
                            autoComplete="off"
                            helperText={errors.onePointValue?.message}
                            InputProps={{

                                endAdornment: <InputAdornment position="end">{t('pln')}</InputAdornment>,
                            }}
                        />

                        <Box textAlign="right" sx={{ py: 3 }}>
                            <LoadingButton loading={btnIsLoading} variant="contained" onClick={handleSubmit(onSubmit)}>{t('save')}</LoadingButton>
                        </Box>
                    </form>


                </Paper>
            </Grid>
        </Grid>
    )

}