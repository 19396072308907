import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NotFound() {

    const { t } = useTranslation('main')
    let navigate = useNavigate()

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100% - 80px)' }}
        >

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <h1>404</h1>
                <Typography variant='h3'>{t('err.pageNotFound')}</Typography>
                <Button onClick={() => navigate('/app/dashboard')} sx={{ mt: 5 }}>{t('goToHomePage')}</Button>

            </Grid>

        </Grid>
    )
}