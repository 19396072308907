import { Grid, Paper, Typography } from "@mui/material";
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { currency } from "../../../components/shared/Utils";

export default function UserPointsBox(props) {


    return (
        <Paper elevation={2} >
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={9}>
                    <Typography variant="h6">{props.title}</Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    <StarBorderOutlinedIcon />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" sx={{ fontSize: { xs: '4em', sm: '2.5em' } }}>{props.amount}</Typography>
                    <Typography variant="light">{currency(props.value)}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}