import { Box, Grid, useTheme, Tooltip } from "@mui/material"
import { NavLink } from "react-router-dom"
import IsAllowed from "./shared/IsAllowed"
import { SidebarContext } from "./shared/SidebarContext"
import { useContext } from "react"
import { tokens } from "../theme"



export default function SidebarItem(props) {

    const [state, setContext] = useContext(SidebarContext)


    const handleClick = () => {
        setContext({ mobileOpened: false })
    }

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const styles = {
        opacity: '.6',
        color: colors.grey[100],
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '6px',
        paddingTop: '7px',
        paddingBottom: '7px',
        fontWeight: 300,
        fontSize: '.9em',
        textDecoration: 'none',
        ':hover': {
            opacity: '1',
        }
    }

    const getStyles = ({ isActive }) => ({
        ...styles,
        opacity: isActive ? '1' : '.6',
    })


    // const tootltipStart = (!state.opened) ? (<Tooltip title={props.text} placement="right">):(<></>);
    // const tootltipEnd = (!state.opened) ? (</Tooltip>):(<></>)

    const link = (
        <NavLink to={props.to} onClick={handleClick} style={getStyles}>
            <Box sx={{ width: 60, textAlign: 'center' }}>
                {props.children}
            </Box>

            <Box component="span"
                sx={{
                    top: 10,
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    ml: '60px',
                    opacity: { xs: 1, sm: (state.opened ? 1 : 0) },
                    transition: 'all .3s ease-in-out',
                    fontWeight: 400,
                    fontSize: '1em'
                }}
            >{props.text}</Box>
        </NavLink>
    )

    const nodes = (
        <Grid container sx={{ mt: 1, p: 1 }}>
            <Grid item xs={12}>
                {(!state.opened) && <Tooltip title={props.text} placement="right">{link}</Tooltip>}
                {state.opened && <>{link}</>}


            </Grid>
        </Grid>)

    if (props.granted) {
        return (<IsAllowed granted={props.granted} >{nodes}</IsAllowed>)
    }

    return (<>{nodes}</>)


}