import { Button, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { useNavigate } from "react-router-dom";


export default function NewPointsButton() {

    const { t } = useTranslation('main')
    const navigate = useNavigate()

    const handleClick = () => {
        return navigate('/app/ideas/add')
    }

    return (
        <Paper elevation={10} sx={{ ml: 3, backgroundColor: '#030f1f', color: '#ffffff', height: '100%' }}>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={9}>
                    <Typography variant="h6" >{t('newIdea')}</Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'right' }}>
                    <TipsAndUpdatesOutlinedIcon />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right', pt: 3 }}>
                <Button disableElevation onClick={handleClick} className="app-btn" variant="contained" size="small" sx={{ py: 1, px: 3 }}>{t('addNewIdea')}</Button>
                    

                </Grid>
            </Grid>


        </Paper>
    )
}