
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteConfirmButton from "./../../../../../../components/shared/DeleteConfirmButton";
import { currency } from '../../../../../../components/shared/Utils';

export default function useColumnsDefinition(events) {


    const css = { align: 'center', headerAlign: 'center' }
    const sortingOrder = { sortingOrder: ['desc', 'asc'] }

    const { t } = useTranslation('main')


    const columns = [
        { field: 'id', headerName: 'ID', width: 70, ...css, ...sortingOrder, },
        {
            field: 'range',
            headerName: t('pointsRange'),
            width: 180,
            valueGetter: (params) => params.row.fromPoints,
            sortComparator: (v1, v2) => v1 - v2,
            renderCell: (params) => {
                if(params.row.fromPoints===params.row.toPoints)
                    return `${params.row.fromPoints} ${(params.row.isLast) ? t('andMore') : ''}`

                return `${params.row.fromPoints}-${params.row.toPoints} ${(params.row.isLast) ? t('andMore') : ''}`
            },
            ...css,
            ...sortingOrder
        },
        {
            field: 'pointsValue',
            headerName: t('pointsValueForEach10'),
            width: 180,
            flex: 1,
            valueGetter: (params) => params.row.value,
            sortComparator: (v1, v2) => v1 - v2,
            renderCell: (params) => {
                return currency(params.row.value)
            },
            ...css,
            ...sortingOrder
        },
        {
            field: 'edit',
            headerName: t('edit'),
            width: 130,
            sortable: false,
            disableColumnMenu: true,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <IconButton onClick={() => events.onEdit(params.row)} color="primary" >
                    <EditOutlinedIcon />
                </IconButton>
            )
        },
        {
            field: 'delete',
            headerName: t('delete'),
            width: 130,
            sortable: false,
            disableColumnMenu: true,
            ...css,
            ...sortingOrder,
            renderCell: (params) => {
                if(params.row.isLast) {
                    return (<DeleteConfirmButton onDelete={() => events.onDelete(params.row)} />)
                }
            }
                
        }

    ];


    return columns;
}

