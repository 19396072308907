
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteConfirmButton from "./../../../../../components/shared/DeleteConfirmButton";

export default function useColumnsDefinition(events) {


    const css = { align: 'center', headerAlign: 'center' }
    const sortingOrder = { sortingOrder: ['desc', 'asc'] }

    const { t } = useTranslation('main')


    const columns = [
        { field: 'id', headerName: 'ID', width: 70, ...css, ...sortingOrder, },
        { field: 'name', headerName: t('name'), width: 90, align: 'left', headerAlign: 'left', ...sortingOrder, flex: 1 },

        {
            field: 'edit',
            headerName: t('edit'),
            width: 130,
            sortable: false,
            disableColumnMenu: true,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <IconButton onClick={() => events.onEdit(params.row)} color="primary" >
                    <EditOutlinedIcon />
                </IconButton>
            )
        },
        {
            field: 'delete',
            headerName: t('delete'),
            width: 130,
            sortable: false,
            disableColumnMenu: true,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <DeleteConfirmButton onDelete={() => events.onDelete(params.row)} />
            )
        }

    ];


    return columns;
}

