import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const useResponseHandler = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('main')
    const errorOptions = { variant: 'error' }
    const successOptions = { variant: 'success' }

    const handleErrorResponse = (err) => {

        const code = err.response.status;

        if (!code) {
            return;
        }



        switch (code) {
            case 422:
                enqueueSnackbar(t('err.formSave'), errorOptions)
                break;
            case 403:
                enqueueSnackbar(t('err.accessDenied'), errorOptions)
                break;
            case 404:
                enqueueSnackbar(t('err.resourceNotFound'), errorOptions)
                break;
            default:
                enqueueSnackbar(t('err.error'), errorOptions)
                break;
        }

    }

    const handleSuccessResponse = (response, customMessages) => {

        const code = response.status;        
      
        if (!code) {
            return;
        }

        const messages = {...{
            201: t('success.saved'),
            204: t('success.deleted'),
        }, ...customMessages}

        


        switch (code) {
            case 201:
                enqueueSnackbar(messages[code], successOptions)
                break;
            case 204:
                enqueueSnackbar(messages[code], successOptions)
                break;

        }

        
    }

    return [handleErrorResponse, handleSuccessResponse];

}