import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import NewPointsButton from "./components/NewPointsButton";
import YourIdeas from "./components/YourIdeas";
import YourPoints from "./components/YourPoints";
import IdeasList from "../ideas/components/IdeasList";
import { userStats } from "../../apis/StatsApi";
import FullPageLoader from "../../components/shared/FullPageLoader";
import DataGridContextProvider from "../../components/shared/datagrid/DataGridContextProvider";



function DashboardPage() {

  const { t } = useTranslation('main');
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})


  useEffect(() => {

    const getStats = async () => {
      const response = await userStats()

      if (response) {
        setData(response.data)
      }
      setIsLoading(false)

    }

    getStats().catch(console.error);

  }, []);


  if (isLoading) {
    return (<FullPageLoader />)
  }


  return (
    <>
      <Grid container rowSpacing={0} sx={{ pr: 3, pb: 5, m: 0 }}>
        <Grid item xs={12} sm={12} md={3} sx={{ pt: 3 }} >
          <NewPointsButton></NewPointsButton>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ pt: 3, pl:  3  }}  >
          <YourIdeas ideas={data?.ideas}></YourIdeas>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ pt: 3 }} >
          <YourPoints points={data?.points}></YourPoints>
        </Grid>

        <Grid item xs={12}>
          <DataGridContextProvider limit="25">
            <IdeasList header={t('lastIdeas')} ></IdeasList>
          </DataGridContextProvider>
        </Grid>
      </Grid>



    </>
  );
}

export default DashboardPage;
