import { Button, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { currency } from "../../../components/shared/Utils";
import { useNavigate } from "react-router-dom";

export default function YourPoints(props) {

    const { t } = useTranslation('main')
    let navigate = useNavigate()

    const points = props.points;

    if (points) {
        return (
            <Paper elevation={2} sx={{ ml: 3, height: '100%' }}>
                <Grid container sx={{ p: 2 }}>
                    <Grid item xs={9}>
                        <Typography variant="h6">{t('yourPoints')}</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <StarBorderOutlinedIcon />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={3} sx={{ textAlign: 'center' }}>

                                <Typography variant="h4" sx={{ fontSize: { xs: '4em', sm: '2.5em' } }}>{ points.available }</Typography>
                                <Typography variant="light">{ currency(points.availablePointsValue) }</Typography>

                            </Grid>
                            <Grid item xs={12} sm={12} md={9} sx={{ pt: 3 }}>
                                <Button onClick={()=>navigate('/app/payout-request/new')} variant="outlined">{t('getYourPoints')}</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


            </Paper>
        )
    }

}