import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdeaFormValidationSchema } from "./components/config/ValidationSchema";
import ManageIdea from "./components/ManageIdea";
import { get, update } from "../../apis/IdeasApi";
import { useParams } from "react-router-dom";
import SidebarContent from "../../components/shared/SidebarContent";
import { useResponseHandler } from "../../hooks/ResponseHandler";
import { fixDates, flatObject, nullToEmptyString } from "../../components/shared/Utils";


function EditIdeaPage() {

  let { id } = useParams();

  const { t } = useTranslation('main')

  const schema = useIdeaFormValidationSchema(true)
  const [isLoading, setIsLoading] = useState(true)
  const [responseCode, setResponseCode] = useState(0)
  const [data, setData] = useState({})
  const [fullData, setFullData] = useState()
  const [handleError] = useResponseHandler()


  const preparaDataFromApi = (data) => {

    return nullToEmptyString(flatObject(data, ['supervisor']), ['plannedImplementationDate', 'implementationDate'])

  }


  useEffect(() => {

    const getIdea = async (ideaId) => {
      await get(ideaId).catch((e) => {
        setResponseCode(e.response.status)
        handleError(e)
      }).then((response) => {
        if (response) {
          setFullData({ ...response.data.idea })
          setData({ idea: preparaDataFromApi(response.data.idea) })

        }

        setIsLoading(false)
      })

    }

    getIdea(id)

  }, [])



  return (
    <SidebarContent isLoading={isLoading} responseCode={responseCode}>
      <ManageIdea
        formTitle={t('editIdea')}
        schema={schema}
        defaultValues={data}
        fullData={fullData}
        save={(dataToSave) => { return update(id, fixDates(dataToSave)) }}
        editMode={true}



      />
    </SidebarContent>

  );
}

export default EditIdeaPage;
