import api from "./config/axiosConfig";


export const getList = async (controller) => {
    return await api.get('points-range', { signal: controller.signal })
}


export const deleteRange = async (id) => {
    return await api.delete('points-range/' + id)
}


export const create = async (data) => {
    return await api.post('points-range', data)
}


export const update = async (id, data) => {
    return await api.put('points-range/'+id, data)
}