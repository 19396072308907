import { Grid } from "@mui/material"
import UserPointsBox from "./UserPointsBox"
import { useTranslation } from "react-i18next"

export default function UserPoints(props) {

    const { t } = useTranslation('main')

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <UserPointsBox
                        title={t('pointsToGet')}
                        amount={props.points.available}
                        value={props.points.availablePointsValue}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <UserPointsBox
                        title={t('takenPoints')}
                        amount={props.points.settled}
                        value={props.points.settledPointsValue}
                    />
                </Grid>
            </Grid>
        </>
    )
}