import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import VerticalLabel from "../../../components/shared/VerticalLabel";
import UserStatusLabel from "./UserStatusLabel";
import IsAllowed from "../../../components/shared/IsAllowed";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useState } from "react";
import StartSaldoDialog from "./StartSaldoDialog";
import { updateSaldo } from "../../../apis/PointsApi";

export default function UserDetails(props) {

    const { t } = useTranslation('main')
    const [open, setOpen] = useState(false)
    const [startSaldo, setStartSaldo] = useState(props.points?.startSaldo)


    let saldoEl = <IsAllowed granted="roleUsersManage">
        {startSaldo + " " + t('pts')}
        <Tooltip title={t('changeStartSaldo')} >
            <IconButton size="small" sx={{ mr: 2, ml: 2 }} onClick={() => setOpen(true)} color="primary" >
                <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    </IsAllowed>



    return (
        <Paper sx={{ overflow: 'hidden' }}>

            <Grid container sx={{ p: 3 }} spacing={2}>
                <Typography sx={{ px: 2, pt: 2 }} variant="h5">{props.user?.firstName} {props.user?.lastName}</Typography>
                <VerticalLabel label={t('username')} value={props.user?.username} />
                <VerticalLabel label={t('employeeSymbol')} value={props.user?.symbol} />
                <VerticalLabel label={t('role')} value={props.user?.role?.name} />

                <Grid item xs={12}>
                    <Typography variant="defaultLabel" component="p">{t('status')}</Typography>
                    <UserStatusLabel status={props.user?.status} />
                </Grid>

                <VerticalLabel label={t('productionGroup')} value={props.user?.companyGroup?.name} />
                <VerticalLabel label={t('email')} value={props.user?.email} />
                <VerticalLabel label={t('startSaldo')} value={saldoEl} />

            </Grid>

            <StartSaldoDialog
                open={open}
                close={() => setOpen(false)}
                dialogTitle={t('changeStartSaldo')}
                defaultValues={{ saldo: startSaldo }}
                save={(data) => {
                    return updateSaldo(props.user.id, data)
                }}
                updateData={(saldo) => { 
                    setStartSaldo(saldo) 
                    props.onSaldoUpdate()
                }}
               
            />

        </Paper>
    )

}