import api from "./config/axiosConfig";
import { buildQuery } from "./utils/QueryBuilder";



export const newPayout = async (data) => {

    return await api.post('payout/request', data)

}

export const getList = async (limit, page, sort, filters, controller) => {

    return await api.get('payout/request' + buildQuery(limit, page, sort, filters))

}

export const download = async (id) => {
    return api.get('payout/request/' + id, {
        responseType: 'blob',
    })
}

export const updateStatus = async (id, data) => {
    return api.put('payout/request/' + id, data)
}
