import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../components/shared/AuthContext';
import FilterForm from "../../../components/shared/form/FilterForm";
import { useUserStatuses } from "./hooks/statuses";


export const useFieldsConfiguration = () => {

    const { t } = useTranslation('main')
    const [state] = useContext(AuthContext)
    const { statuses } = useUserStatuses()

    return [
        { type: 'text', name: 'firstName', label: t('firstName') },
        { type: 'text', name: 'lastName', label: t('lastName') },
        { type: 'text', name: 'username', label: t('username') },
        { type: 'text', name: 'symbol', label: t('employeeSymbol') },
        { type: 'select', name: 'companyGroup', label: t('productionGroup'), options: state.dictionary.companyGroups },
        { type: 'select', name: 'status', label: t('status'), options: statuses },

    ];
}


export const defaultFilters = {

    firstName: '',
    lastName: '',
    username: '',
    symbol: '',
    companyGroup: '',
    status: '',


}


export default function UsersFilters(props) {

    return (
        <FilterForm
            fields={useFieldsConfiguration()}
            defaultFilters={defaultFilters}

        />
    );
}