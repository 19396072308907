import { Chip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataGridContext } from "../datagrid/DataGridContext";



const ChipLabel = (props) => {
    return (
        (<span>
            {props.label}: <strong>{props.value}</strong>
        </span>)
    )
}


const getAllLabels = (fields) => {

    const labels = {}

    fields.map(field => {
        if (field.type === 'fromtogroup') {
            labels[field.fromField.name] = field.fromField
            labels[field.toField.name] = field.toField

        } else {
            labels[field.name] = field
        }
    })

    return labels;

}






const getValue = (field, value) => {

   

    if (field.type === 'multiselect') {
    

        if(Array.isArray(value)) {
            return field.options.filter(obj => value.includes(obj.id))
            .map(obj => obj.name)
            .join(', ');

        }
        return '';
       
    }

    if (field.type === 'select') {       
        const v = field.options.find(item => item.id == value);
        return (v) ? v.name : ''
    }


    if (field.type === 'date') {

        if (typeof value === 'object')
            return value.format('YYYY-MM-DD')



    }


    return value;
}






export default function ActiveFilters(props) {

    const [dataGridState, setDataGridContext] = useContext(DataGridContext)
    const [activeFilters, setActiveFilters] = useState([])




    useEffect(() => {
        

        const updateFilters = () => {
            const active = []
            const allFilters = dataGridState.filters;
            const allLabels = getAllLabels(props.fields)

            if (allFilters !== null) {
                for (const filterItem in allFilters) {



                    if (allFilters[filterItem] || allFilters[filterItem] === 0) {
                        const value = getValue(allLabels[filterItem], allFilters[filterItem])
                        if(value)
                        active.push({ name: filterItem, label: allLabels[filterItem].label, value: value })
                    }
                }
            }

           
            setActiveFilters(active)

        }

        updateFilters()
    }, [dataGridState.filters])




    const handleDelete = (key) => {

        let fieldConf = props.fields.filter(obj => obj.name === key.name);

        let newFilters = { ...dataGridState.filters }

        if(fieldConf.length === 1 && fieldConf[0].type === 'multiselect') {
            newFilters[key.name] = []
        } else {
            newFilters[key.name] = ''
        }

        

     

        let filtersData = null;
        for (const property in newFilters) {
            if (newFilters[property]) {
                filtersData = newFilters;
                break;
            }

        }

        setDataGridContext({
            filters: filtersData
        })
    }

    return (
        <>
            {activeFilters.map(item => (

                <Chip sx={{ mr: 1, mt: 1 }} key={item.name} size="small" label={<ChipLabel value={item.value} label={item.label} />} onDelete={() => handleDelete(item)} />
            ))}
        </>

    )
}