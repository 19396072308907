import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DataGridContextProvider from "../../components/shared/datagrid/DataGridContextProvider";
import UsersList from "./components/UsersList";

export default function UsersPage() {

    const { t } = useTranslation('main')

    const navigate = useNavigate()

    const handleClick = () => {
        return navigate('/app/users/add')
    }

    return (
        <>
            <Grid container rowSpacing={0} sx={{ pr: 3, pb: 5, m: 0 }}>
                <Grid item xs={12} sm={6} sx={{ pl: 3, pt: 3 }} >
                    <Typography variant="h1" >{t('menu.users')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ pt: 3, textAlign: 'right' }} >
                    <Button disableElevation onClick={handleClick} variant="contained" size="small" sx={{ py: 1, px: 3 }}>{t('addNewUser')}</Button>
                </Grid>
                <Grid item xs={12}>
                    <DataGridContextProvider>
                        <UsersList/>
                    </DataGridContextProvider>
                </Grid>
            </Grid>
        </>
    );

}