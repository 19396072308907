import { Box, Paper, Typography } from '@mui/material';
import fileDownload from 'js-file-download';
import { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { getList, download } from '../../../apis/PayoutApi';
import { DataGridContext } from '../../../components/shared/datagrid/DataGridContext';
import ServerDataGrid from '../../../components/shared/datagrid/ServerDataGrid';
import ActiveFilters from '../../../components/shared/form/AciveFilters';
import { useResponseHandler } from '../../../hooks/ResponseHandler';
import { useColumnsDefinition } from './config/config';
import PayoutRequestFilters, { defaultFilters, useFieldsConfiguration } from './PayoutRequestFilters';







export default function PayoutRequestLIst(props) {

    const { t } = useTranslation('main')

    const [dataGridState, setDataGridContext] = useContext(DataGridContext)
    const [handleError] = useResponseHandler()


    const handleDownloadClick = (params) => {

        const downloadRequest = async () => {
            await download(params.id).catch((e) => {
                handleError(e)

            }).then((res) => {

                res && fileDownload(res.data, `Wniosek_nr_${params.id}.pdf`)
            })
        }

        downloadRequest()

    }


    useEffect(() => {

        const getPayoutRequests = async () => {

            if (!dataGridState.reload)
                return false;

            setDataGridContext({ isLoading: true })
            const result = await getList(dataGridState.limit, dataGridState.page, dataGridState.sort, dataGridState.filters)

            if (result) {
                setDataGridContext({
                    isLoading: false,
                    rows: result.data.data,
                    totalRows: result.data.paginator.totalRecords,
                    reload: false
                })
            } else {
                setDataGridContext({ reload: false })
            }
        }

        getPayoutRequests();

    }, [dataGridState.reload])



    return (
        <>

            <Paper elevation={2} sx={{ ml: 3, mt: 3 }}>

                <Box display="flex" justifyContent="flex-end" sx={{ pl: 2, pr: 2, pt: 2 }}>

                    <PayoutRequestFilters />
                </Box>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <ActiveFilters defaultFilters={defaultFilters} fields={useFieldsConfiguration()} />
                </Box>
                <Box >
                    <ServerDataGrid
                        columns={useColumnsDefinition(handleDownloadClick)}

                    />

                </Box>

            </Paper>

        </>
    );
}