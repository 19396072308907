import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../../components/shared/Utils";
import { useNavigate } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import PointsLabel from "./../PointsLabel"
import { useIsAllowed } from "../../../../components/shared/IsAllowed";



const css = { align: 'center', headerAlign: 'center' }
const sortingOrder = { sortingOrder: ['desc', 'asc'] }

export const useColumnsDefinition = () => {

    const { t } = useTranslation('main')





    const columns = [
        
        {
            field: 'lastName',
            headerName: t('fullname'),
            flex: 1,
            ...css,
            ...sortingOrder,
            valueGetter: (params) =>
                `${params.row.lastName} ${params.row.firstName}`,
        },
        {
            field: 'u.symbol',
            headerName: t('employeeSymbol'),
            flex: 1,
            ...css,
            ...sortingOrder,
            valueGetter: (params) =>
                `${params.row.symbol || '-'}`,
        },

        {
            field: 'total',
            headerName: t('pointsAmount'),
            width: 200,
            ...css,
            ...sortingOrder,
            renderCell: (params) => (
                <PointsLabel points={params.row.total} type="new" />
            )

        },



    ];

    return columns;
}