import { Grid } from '@mui/material'
import { useTranslation } from "react-i18next"
import SidebarContentTitle from '../../components/shared/SidebarContentTitle';
import PaperButton from '../../components/shared/PaperButton';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { buildQuery } from '../../apis/utils/QueryBuilder';
import { useTimeConverter } from '../../hooks/TimeConverter';

export default function ReportsPage() {

    const { t } = useTranslation('main')
    const { getToday, getYearBeforeFirstDay } = useTimeConverter()

    const queryString = buildQuery(null, null, null, { createdFrom: getYearBeforeFirstDay().toISOString().slice(0, 10), createdTo: getToday().toISOString().slice(0, 10) })
    const queryString2 = buildQuery(null, null, [{ field: 'total', sort: 'desc' }], { createdFrom: getYearBeforeFirstDay().toISOString().slice(0, 10), createdTo: getToday().toISOString().slice(0, 10) })

    return (<>
        <SidebarContentTitle title={t('menu.reports')} />

        <Grid container rowSpacing={0} sx={{ pr: 3, pb: 5, m: 0 }}>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('reports.time')}
                    url={`/app/reports/by-time${queryString}`}
                    btnTitle={t('generate')}
                    icon={<AccessTimeOutlinedIcon />}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('reports.employees')}
                    url={`/app/reports/by-employees`}
                    btnTitle={t('generate')}
                    icon={<PersonOutlineOutlinedIcon />}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('reports.status')}
                    url={`/app/reports/by-status${queryString}`}
                    btnTitle={t('generate')}
                    icon={<DoneAllOutlinedIcon />}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ pt: 3 }} >
                <PaperButton
                    title={t('reports.points')}
                    url={`/app/reports/by-points${queryString2}`}
                    btnTitle={t('generate')}
                    icon={<StarOutlineOutlinedIcon />}
                />
            </Grid>



        </Grid>
    </>)
}